/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
import React from 'react';
import arrowRigth from '../../res/images/arrowrigth.svg';

const ButtonGo = ({ setOpen }) => {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid */}
      <a className={'callAction-courses'} target={'_blank'} rel={'noreferrer'} onClick={() => setOpen(true)}>
        <div className={'__callAction'}>
          <li>Inscribirse<img alt={'icon-user'} className={'icon-user'} src={arrowRigth} /></li>
        </div>
      </a>
    </>
  );
};

export default ButtonGo;
