import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './components/login/login';
import DiscStateContextProvider from './contexts/discState-context';
import SaveDiscAnswersContextProvider from './contexts/saveDiscAnswers-context';
import MaterialWrapper from './components/mdc/MaterialWrapper';
import NotFound from './components/sites/NotFound';
import LoggedRoutes from './components/loggedRoutes/loggedRoutes';
import LoginRoutesData from './data/login-routes-data';
import RecoveryPassword from './components/recoveryPassword/RecoveryPassword';
import DashboardDisc from './components/dragdropDisc/DashboardDisc';
import ResponsePayments from './components/shop/ResponsePayments';
import DashboardDiscResult from './components/dashboardDiscResult/DashboardDiscResult';
import HomeTest from './components/homeTest/HomeTest';

const App = () => (
  <BrowserRouter>
    <LoginRoutesData>
      <MaterialWrapper>
        <Switch>
          <Route exact path={'/'}>
            <Login />
          </Route>
          <Route exact path={'/prueba'}>
            <DashboardDiscResult />
          </Route>
          <Route exact path={'/home/responsepayments'}>
            <ResponsePayments />
          </Route>
          <Route exact path={'/test'}>
            <SaveDiscAnswersContextProvider>
              <DashboardDisc />
            </SaveDiscAnswersContextProvider>
          </Route>
          <Route exact path={'/recovery'}>
            <RecoveryPassword />
          </Route>
          <Route exact path={'/hometest'}>
            <HomeTest />
          </Route>
          <DiscStateContextProvider>
            <LoggedRoutes />
          </DiscStateContextProvider>
          <Route><NotFound /></Route>
        </Switch>
      </MaterialWrapper>
    </LoginRoutesData>
  </BrowserRouter>
);

export default App;
