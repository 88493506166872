/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Dialog, DialogTitle } from '@rmwc/dialog';
import ButtonMdc from '../../mdc/button/ButtonMdc';
import './modalDetails.css';

const ModalDetails = ({ details }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <ButtonMdc className={'button'} onClick={() => setIsOpen(true)}>
        <div className={'button-conten'}>
          Detalles
          <span className={'mdi mdi-arrow-right iconArrow'} />
        </div>
      </ButtonMdc>
      <Dialog
        open={isOpen}
        className={'cardDialog'}
        onClose={() => setIsOpen(false)}
        preventOutsideDismiss
      >
        <div className={'conten-Details'}>
          <span className={'close-x mdi mdi-close'} onClick={() => setIsOpen(false)} />
          <DialogTitle className={'Dialog-title '}>Detalles</DialogTitle>
          <p className={'dialog-conten-text'}><b>Fortalezas:</b> {details.fortalezas}</p>
          <p className={'dialog-conten-text'}><b>Oportunidades de mejora:</b> {details.oportunidades}</p>
          <p className={'dialog-conten-text'}><b>Recomendaciones para ser exitoso en el mundo de la tecnología:</b> {details.Recomendaciones}</p>
          <p className={'text-end'}>{details.text}</p>
        </div>
      </Dialog>
    </div>
  );
};

export default ModalDetails;
