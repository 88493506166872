/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { VictoryPie, VictoryLabel } from 'victory';
import './donutGraphic.css';

const DonutGraphic = ({
  letter, d, i, c, s,
}) => (
  <div style={{ position: 'relative' }}>
    <VictoryPie
      colorScale={['#1496F9', '#E32256', '#FFB126', '#35CCDB']}
      style={{ labels: { fill: 'white' } }}
      innerRadius={110}
      labelRadius={120}
      labelComponent={
        <>
          <VictoryLabel
            textAnchor={'middle'}
            verticalAnchor={'middle'}
            x={200}
            y={200}
            style={{ fontSize: 35 }}
            text={`Perfil Tipo ${letter || ' '}`}
          />
        </>
    }
      data={[
        { x: 'D', y: d },
        { x: 'I', y: i },
        { x: 'S', y: s },
        { x: 'C', y: c },
      ]}
    />
  </div>
);

export default DonutGraphic;
