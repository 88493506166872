import React from 'react';
import recoveryImg from '../../res/images/recoverypassword.png';
import './RecoveryPass.css';
import NewPassword from './NewPassword';
import EmailRecovery from './EmailRecovery';

const RecoveryPassword = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  return (
    <>
      <div className={'recoveryPassContainer'}>
        <div className={'recoveryPass'}>
          <div className={'recoveryImg'}>
            <img alt={'recovery-password'} src={recoveryImg} />
          </div>
          <h5><span>Cambio de</span>Contraseña</h5>
          {token ? <NewPassword token={token} /> : <EmailRecovery />}
        </div>
      </div>
    </>

  );
};

export default RecoveryPassword;
