import React from 'react';
import { CircularProgress } from '@rmwc/circular-progress';
import './loader.css';

const Loader = () => (
  <div style={{ textAlign: 'center' }}>
    <CircularProgress size={72} />
  </div>
);

export default Loader;
