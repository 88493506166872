/* eslint-disable indent */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import MDIcon from '../mdc/MDIcon';
import BXLogo from '../../res/images/bx-logo.svg';
import './hamburguerMenu.css';
import firebase from '../../firebase';

const HamburguerMenu = (props) => {
  const { itemsMenu } = props;
  const [isOpen, setIsOpen] = useState(false);

  const signOut = () => {
    try {
      localStorage.clear();
      firebase.auth().signOut();
    } catch (e) { // an error
    }
  };
  return (
    <>
      <header id={'menu-header'}>
        <div className={'container-menu'}>
          <figure>
            <img alt={'BX Logo'} src={BXLogo} />
          </figure>
          <span onClick={() => setIsOpen(!isOpen)} onKeyDown={() => setIsOpen(!isOpen)} className={'span-icon'}>
            <MDIcon icon={isOpen ? 'backburger' : 'menu'} />
          </span>
        </div>
        <ul className={`menu-status${isOpen}`}>
          {itemsMenu.map((index) => (<a key={index.href} href={index.href} className={'link-href'}>
            <li key={index.name}>{index.name}</li>
          </a>))}
          <a className={'link-href'} href={'/'} onClick={() => { signOut(); }}>
            <li>Salir</li>
          </a>
        </ul>
      </header>
      <div className={`menu-status${isOpen} overlay`} onClick={() => setIsOpen(false)} onKeyDown={() => setIsOpen(false)} />
    </>
  );
};
export default HamburguerMenu;
