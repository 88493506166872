import { api, escalateError, getResponseData } from './index';

export default class DataApi {
  static createItemInDb(email) {
    return api.post('/newsletter', {
      email,
    })
      .then(getResponseData)
      .catch(escalateError);
  }
}
