import React from 'react';
import './cardProfile.css';
import RadarChart from '../GraphicDics/RadarChart';
import ModalDetails from './modalDetails/ModalDetails';

const capital = (name) => (name ? name?.charAt(0).toUpperCase() + name?.slice(1) : ' ');

const CardProfile = ({
  letter,
  name,
  lastname,
  typeUser,
  stats,
  details,
}) => (
  <div className={'card-profile'}>
    <h5 className={'name-title'}>
      {capital(name)} {capital(lastname)}
    </h5>
    <p>{typeUser ? ' ' : 'Estudiante'}</p>
    <RadarChart
      d={(stats[0].d?.percentage || 0) / 7}
      i={(stats[0].i?.percentage || 0) / 7}
      s={(stats[0].s?.percentage || 0) / 7}
      c={(stats[0].c?.percentage || 0) / 7}
    />
    <div className={'profile-points'}>
      <p><span className={'mdi pointD mdi-checkbox-blank-circle'} /> Perfil D</p>
      <p><span className={'mdi pointI mdi-checkbox-blank-circle'} /> Perfil I</p>
      <p><span className={'mdi pointS mdi-checkbox-blank-circle'} /> Perfil S</p>
      <p><span className={'mdi pointC mdi-checkbox-blank-circle'} /> Perfil C</p>
    </div>
    <div className={'resDisc'}>
      <div className={'type'}><text>Tipo de resultado</text></div>
      <div className={'leter'}>{letter} </div>
    </div>
    <ModalDetails details={details} />
  </div>
);

export default CardProfile;
