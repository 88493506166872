/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@rmwc/circular-progress';
import Axios from 'axios';
import { Snackbar } from '@rmwc/snackbar';

const NewPassword = (props) => {
  const { token } = props;
  const [loadToken, setLoadToken] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const [passOne, setPassOne] = useState('');
  const [passTwo, setPassTwo] = useState('');
  const [diffPass, setDiffPass] = useState(false);
  const [ivalidPass, setIvalidPass] = useState(true);
  const [state, setState] = useState(false);
  const passValid = () => {
    if (passOne !== passTwo) {
      setDiffPass(true);
    } else {
      setDiffPass(false);
    }
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$.@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){10,}$/;
    if (regex.test(passOne)) {
      setIvalidPass(false);
    } else {
      setIvalidPass(true);
    }
  };
  useEffect(async () => {
    const { data } = await Axios.post(`${process.env.REACT_APP_BASE_URL}/recovery/validateToken`, {
      token,
    });
    // finish loading
    setLoadToken(true);
    setValidToken(data.isValid);
  }, []);

  useEffect(() => {
    passValid();
  }, [passOne, passTwo]);

  const sendPassword = () => {
    if (!(diffPass || ivalidPass)) {
      try {
        Axios.post(`${process.env.REACT_APP_BASE_URL}/recovery/change`, {
          password: passOne,
          token,
        });
        setState(true);
        document.getElementById('passOneId').innerText = '';
        document.getElementById('passTwoId').innerText = '';
        setTimeout(() => {
          window.location = '/';
        }, 2500);
      // eslint-disable-next-line no-empty
      } catch (error) { }
    }
  };

  const errMsg = (diffPass || ivalidPass) ? 'ivalidInput' : '';

  return (
    <>
      {
      !loadToken ? <><CircularProgress /> <h4>Cargando...</h4> </> : validToken ? <>
        <div id={'newPassOne'}>
          <input
            id={'passOneId'}
            className={errMsg}
            type={'password'}
            placeholder={'Cambiar contraseña'}
            onChange={(e) => {
              setPassOne(e.target.value);
            }}
          />
        </div>
        <input
          id={'passTwoId'}
          className={errMsg}
          type={'password'}
          placeholder={'Confirmar contraseña'}
          onChange={(e) => {
            setPassTwo(e.target.value);
          }}
        />

        {ivalidPass ? <label style={{ color: 'red' }}>**Formato de contraseña incorrecto</label> : <></>}
        {diffPass ? <label style={{ color: 'red' }}>**Las contraseñas son diferentes</label> : <></>}
        <a onClick={() => {
          sendPassword();
        }}
        >Terminar
        </a>
        <Snackbar
          open={state}
          onClose={() => setState(false)}
          message={'Contraseña modificada correctamente'}
        />
      </> : <> <h4>Este enlace ha expirado o ya ha sido utilizado</h4> <a href={'/'}> Regresar</a> </>
}
    </>
  );
};

export default NewPassword;
