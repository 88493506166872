/* eslint-disable react/jsx-closing-tag-location */
import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import HomeEnterprise from '../pages/homeEnterprise';
import HomePerson from '../pages/homePerson';
import NotFound from '../sites/NotFound';
import LoginRoutesContext from '../../contexts/login-routes-context';
import Admin from '../adminPage/Admin';
import DashboardDiscResult from '../dashboardDiscResult/DashboardDiscResult';
import LoginAdmin from '../adminPage/Login';

const LoggedRoutes = () => {
  const [isEnterprise, setIsEnterprise] = useState(false);
  const [email, setEmail] = useState('');
  const {
    userEmail, isEnterpriseContext, role, setRole,
  } = useContext(LoginRoutesContext);
  const [token, setToken] = useState(null);

  useEffect(() => {
    setEmail(userEmail);
    setIsEnterprise(isEnterpriseContext);
    const lsToken = localStorage.getItem('token');
    setToken(lsToken);
  }, [userEmail, isEnterpriseContext, role]);
  return (
    <Switch>
      {email && (<Route exact path={'/home'}>
        {isEnterprise
          ? <HomeEnterprise />
          : <HomePerson />}
      </Route>)}
      {email && (<Route exact path={'/home/mis-cursos'}>
        {isEnterprise
          ? <HomeEnterprise />
          : <DashboardDiscResult />}
      </Route>)}
      <Route exact path={'/bx-admin/dashboard'}>
        {(role === 'super admin' && token) ? <Admin setRole={setRole} /> : <NotFound />}
      </Route>
      <Route exact path={'/bx-admin'}>
        <LoginAdmin setRole={setRole} />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default LoggedRoutes;
