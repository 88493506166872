import React from 'react';
import { Typography } from '@rmwc/typography';

import Hexagono from '../../res/images/Rectangle 235.png';
import HexagonoTwo from '../../res/images/Rectangle 236.png';

import './programs.css';

const Programs = () => (
  <div className={'container-programs'}>
    <div className={'container-programs-section-one'}>
      <div className={'container-img-first'}>
        <img alt={'bx-hexagono'} src={Hexagono} />
      </div>
      <div className={'container-img-second'}>

        <div className={'text-complet'}>
          <Typography>Tienes una
            <Typography className={'text-resalt'}> característica especial </Typography>
            que te hace indispensable, juntos podremos construir un
            <Typography className={'text-resalt'}> futuro increíble</Typography>
          </Typography>
          <a href={process.env.REACT_APP_EMPLEOS_LOGIN} target={'_blank'} className={'link-style'} rel={'noreferrer'}>
            <Typography className={'button-start-now'}>¡Empieza ahora!
            </Typography>
          </a>
        </div>
      </div>
    </div>
    <div className={'container-programs-section-two'}>
      <div className={'containe-img-third'}>
        <div className={'text-complet-two'}>
          <Typography>Te acompañamos en tu crecimiento, enseñándote nuevas
            tecnologías de información
          </Typography>

          <a href={process.env.REACT_APP_MOODLE_LOGIN} target={'_blank'} className={'link-style'} rel={'noreferrer'}>
            <Typography className={'button-see-programs'}> Ver programas</Typography>
          </a>

        </div>
      </div>
      <div className={'container-img-fourth'}>
        <img alt={'bx-hexagono'} src={HexagonoTwo} />
      </div>
    </div>
  </div>
);

export default Programs;
