/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import { Snackbar } from '@rmwc/snackbar';
import { TabBar, Tab } from '@rmwc/tabs';
import { Checkbox } from '@rmwc/checkbox';
import { TextField } from '@rmwc/textfield';
import ButtonMdc from '../mdc/button/ButtonMdc';
import Popup from '../popup/popup';
import Utils from '../../utils/Utils';
import './register.css';
import DataApi from '../../api/LoginApi';
import firebase from '../../firebase';
import Loader from '../loader/loader';

const Register = () => {
  const history = useHistory();
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordConfirmError, setPasswordConfirmError] = useState('');
  const [nameError, setNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [secondLastName, setSecondLastName] = useState('');
  const [section, setSection] = useState(true);
  const [employment, setEmployment] = useState('');
  const [employmentError] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [businessNameError] = useState('');
  const [sector, setSector] = useState('');
  const [sectorError] = useState('');
  const [, setText] = useState('');
  const [, setOpen] = useState(false);
  const [state, setState] = useState(false);
  const [textMessage, setTextMessage] = useState('');
  const [disable, setDisable] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const validateFields = () => {
    if (name.length <= 0) {
      setNameError('Escriba su nombre');
      return false;
    }
    setNameError('');

    if (lastName.length <= 0) {
      setLastNameError('Escriba su apellido');
      return false;
    }
    setLastNameError('');

    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$.:;,@$!%*?&#=])([A-Za-z\d$.:;,@$!%*?&#=]|[^ ]){10,}$/;
    if (!regex.test(password)) {
      setPasswordError('La contraseña debe tener por lo menos: Diez dígitos, una mayúscula, una minúscula, un número y un carácter especial: $.:;,@$!%*?&#=');
      return false;
    }
    setPasswordError('');

    if (password !== passwordConfirm) {
      setPasswordConfirmError('La contraseña no coincide');
      return false;
    }
    setPasswordConfirmError('');

    if (!Utils.validateEmail(email)) {
      setEmailError('El correo electrónico no es válido');
      return false;
    }

    if (!clicked) {
      return false;
    }

    setDisable(false);
    return true;
  };
  useEffect(() => {
    validateFields();
  }, [name, passwordConfirm, lastName, password, email, clicked]);

  const sendData = async (section) => {
    setDisable(true);
    setDisabled(false);
    if (validateFields()) {
      firebase.auth().createUserWithEmailAndPassword(email, password)
        .then(() => {
          const data = section ? {
            name,
            lastName,
            secondLastName,
            password,
            email,
            id: firebase.auth().currentUser.uid,
            userInfo: '',
            permissionId: '',
            token: '',
            isEnterprise: false,
          } : {
            email,
            id: firebase.auth().currentUser.uid,
            password,
            userInfo: '',
            permissionId: '',
            token: '',
            isEnterprise: true,
            name,
            lastName,
            secondLastName,
            employment,
            businessName,
            sector,
          };
          DataApi.postUser(data)
            .then((todos) => {
              localStorage.setItem('userID', todos.data.userId);
              setTextMessage('Usuario creado');
              setTextMessage('Registro exitoso');
              setOpen(true);
              setTimeout(() => {
                history.go(0);
              }, 2000);
            })
            .catch((err) => {
              setText(`Please retry ${(err.message || 'Unexpected error')}`);
              setTextMessage('Error en la creacion del usuario');
              setDisabled(true);
              setOpen(true);
            }).finally(() => {
              setDisabled(true);
              setState(true);
            });
        }).catch((err) => {
          if (err.code === 'auth/email-already-in-use') {
            setTextMessage('El correo ya existe');
            setDisabled(true);
            setState(true);
          }
          setDisabled(true);
          setOpen(true);
          setText(`Please retry ${(err.message || 'Unexpected error')}`);
        });
    }
  };

  return (
    <>
      {disabled
        ? <Grid style={{ margin: '0px', padding: '0px' }}>
          <GridCell desktop={12} tablet={12} phone={12} style={{ margin: '0px', padding: '0px' }}>
            <TabBar>
              <Tab onClick={() => setSection(true)}>Persona</Tab>
              <Tab onClick={() => setSection(false)}>Empresa</Tab>
            </TabBar>
            <Grid>
              <GridRow>
                <GridCell desktop={12} tablet={12} phone={12}>
                  <TextField
                    label={'Nombre'}
                    value={name}
                    onChange={(e) => setName((e.target.value).normalize('NFD').replace(/[\u0300-\u036f]/g, ''))}
                    invalid={nameError.length > 0}
                    helpText={{ validationMsg: true, children: nameError }}
                    style={{ backgroundColor: '#fff' }}
                  />
                  <TextField
                    label={'Primer Apellido'}
                    value={lastName}
                    onChange={(e) => setLastName((e.target.value).normalize('NFD').replace(/[\u0300-\u036f]/g, ''))}
                    invalid={lastNameError.length > 0}
                    helpText={{ validationMsg: true, children: lastNameError }}
                    style={{ backgroundColor: '#fff' }}
                  />
                  <TextField
                    label={'Segundo Apellido'}
                    value={secondLastName}
                    onChange={(e) => setSecondLastName((e.target.value).normalize('NFD').replace(/[\u0300-\u036f]/g, ''))}
                    style={{ backgroundColor: '#fff' }}
                  />
                  <TextField
                    label={'Correo'}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    invalid={!Utils.validateEmail(email)}
                    helpText={{ validationMsg: true, children: emailError }}
                    style={{ backgroundColor: '#fff' }}
                  />
                  {!section && (<>
                    <TextField
                      label={'Cargo'}
                      value={employment}
                      onChange={(e) => setEmployment(e.target.value)}
                      invalid={employment.length < 3}
                      helpText={{ validationMsg: true, children: employmentError }}
                      style={{ backgroundColor: '#fff' }}
                    />
                    <TextField
                      label={'Escribe la razón social'}
                      value={businessName}
                      onChange={(e) => setBusinessName(e.target.value)}
                      invalid={businessName.length < 3}
                      helpText={{ validationMsg: true, children: businessNameError }}
                      style={{ backgroundColor: '#fff' }}
                    />
                    <TextField
                      label={'Sector'}
                      value={sector}
                      onChange={(e) => setSector(e.target.value)}
                      invalid={sector.length < 2}
                      helpText={{ validationMsg: true, children: sectorError }}
                      style={{ backgroundColor: '#fff' }}
                    />
                  </>
                  )}
                  <TextField
                    type={'password'}
                    label={'Contraseña'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    invalid={passwordError.length > 8}
                    helpText={{ validationMsg: true, children: passwordError }}
                    style={{ backgroundColor: '#fff' }}
                  />
                  <TextField
                    type={'password'}
                    label={'Repetir contraseña'}
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    invalid={passwordConfirmError.length > 0}
                    helpText={{
                      validationMsg: true,
                      children: passwordConfirmError,
                    }}
                    style={{ backgroundColor: '#fff' }}
                  />
                  <div className={'checkbox-terms'}>

                    <Checkbox
                      onClick={() => {
                        setClicked(!clicked);
                        if (!clicked) setIsChecked(!isChecked);
                      }}
                      className={'checkbox-terms-check'}
                    >
                      Politica de tratamiento de datos
                    </Checkbox>

                  </div>
                  <div>
                    {isChecked && <Popup
                      openDialog={isChecked}
                      onChange={(event) => {
                        if (event) setIsChecked(false);
                      }}
                    />}

                  </div>

                  <div className={'send-enterprice'}>

                    <ButtonMdc
                      className={disable ? 'sendLink-disable button-full' : 'button-full'}
                      label={'Registrarse'}
                      disabled={disable && !isChecked}
                      raised
                      onClick={() => sendData(section)}
                    />
                  </div>
                </GridCell>
              </GridRow>
            </Grid>
          </GridCell>
        </Grid>
        : <Grid>
          <div style={{ width: '335px' }}>
            <Loader />
          </div>
        </Grid>}
      <Snackbar
        open={state}
        onClose={() => setState(false)}
        message={textMessage}
      />
    </>
  );
};

export default Register;
