import React from 'react';
import { VictoryBar } from 'victory';

const ProgressBar = ({ leter, value, color }) => {
  const data = [
    { x: 0, y: 1, fillColor: '#D9D9D9' },
    { x: 0, y: (value / 100), fillColor: color },
  ];
  return (
    <>
      <div style={{
        margin: '0',
        marginBottom: '-10px',
        display: 'flex',
        gap: '35%',
      }}
      >
        <p style={{ width: '170px', margin: '0' }}>Perfil tipo {leter}</p>
        <p style={{ margin: '0', fontWeight: '200' }}>{value.toFixed(1)}%</p>
      </div>
      <div style={{ height: '50px', width: '200px' }}>
        <VictoryBar
          data={data}
          style={{
            data: { fill: ({ datum }) => (datum.fillColor), width: 20, transform: 'translateX(-410px) translateY(-100px)' },
          }}
          domain={{ y: [0, 0.3] }}
          horizontal
        />
      </div>
    </>
  );
};

export default ProgressBar;
