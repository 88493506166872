/* eslint-disable import/order */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import ButtonMdc from '../../mdc/button/ButtonMdc';
import imagD from '../../../res/images/disc_D.png';
import imagI from '../../../res/images/disc_I.png';
import imagS from '../../../res/images/disc_S.png';
import imagC from '../../../res/images/disc_C.png';
import './modalInfoDisc.css';
import { Carousel } from 'react-responsive-carousel';

// Desde aqui

const ModalInfoDisc = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest('.background-info')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  const openCarousel = () => {
    setIsOpen(true);
  };
  return (
    <>
      <ButtonMdc
        className={'button-home-ingresar'}
        onClick={() => openCarousel(true)}
      >Información &nbsp;
        <span className={'mdi mdi-information-outline'} style={{ color: 'white', fontSize: '15pt' }} />
      </ButtonMdc>
      <Dialog
        maxWidth={'xl'}
        fullWidth
        open={isOpen}
      >
        <DialogContent style={{ padding: '0' }}>
          <div className={'background-info'}>
            <span className={'close-x mdi mdi-close'} onClick={() => setIsOpen(false)} />
            <text>&emsp;&emsp;<strong>Según tu resultado, obtendrás uno de los siguientes perfiles</strong></text>
            <Carousel
              autoPlay
              showThumbs
              showArrows
              showStatus={false}
              infiniteLoop
              transitionTime={500}
            >
              <div className={'conten-grid'}>
                <div className={'conten-dialog-title'}>
                  <h2>Perfil D</h2>
                  <p className={'conten-dialog-text'}>
                    El perfil D, en las pruebas DISC representa el Dominio. Las personas con personalidad
                    Dominante tienden a ser directas. Tienen una personalidad decidida y asertiva, les gusta tomar riesgos y
                    enfrentar desafíos sin rendirse fácilmente. Asumen el liderazgo con facilidad y toman decisiones rápidamente.
                  </p>
                  <p className={'conten-dialog-text'}>
                    Pero, ten en cuenta que un alto nivel de dominio también puede traer consigo muchos desafíos, por lo tanto,
                    si llegas a tener un resultado D, considera equilibrar tu dominio con habilidades sociales y emocionales,
                    como la ayuda, empatía y escucha.
                  </p>
                  <p className={'conten-dialog-text'}>
                    <strong>
                      Serás decidido, audaz y estarás dispuesta a alcanzar el éxito.
                    </strong>
                  </p>
                </div>
                <div className={'backgr-imag'}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={imagD} style={{ width: '90%', height: 'auto', backgroundColor: '#ffffff00' }} />
                  </div>
                </div>
              </div>
              <div className={'conten-grid'}>
                <div className={'conten-dialog-title'}>
                  <h2>Perfil I</h2>
                  <p className={'conten-dialog-text'}>
                    El perfil I, en las pruebas DISC representa la influencia. Las personas con personalidad influyente tienden a ser sociables,
                    carismáticas y persuasivas.
                    Disfrutan interactuar con los demás, son expresivas, enérgicas y se sienten cómodas asumiendo roles de comunicación y
                    motivación, establecen conexiones sociables y suelen asumir los desafíos de forma extrovertida.
                  </p>
                  <p className={'conten-dialog-text'}>
                    Pero, ten en cuenta que un alto nivel de influencia también puede traer consigo la tendencia a perder el enfoque en
                    los detalles o a ser impulsivo en la toma de decisiones, por lo tanto,
                    si llegas a tener un resultado I, considera encontrar un equilibrio entre tu influencia, el enfoque y la disciplina.
                  </p>
                  <p className={'conten-dialog-text'}>
                    <strong>
                      Serás un líder inspirador, capaz de guiar a otros hacia el éxito
                    </strong>
                  </p>
                </div>
                <div className={'backgr-imag'}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={imagI} style={{ width: '90%', height: 'auto', backgroundColor: '#ffffff00' }} />
                  </div>
                </div>
              </div>
              <div className={'conten-grid'}>
                <div className={'conten-dialog-title'}>
                  <h2>Perfil S</h2>
                  <p className={'conten-dialog-text'}>
                    El perfil S, en las pruebas DISC representa la serenidad.
                    Las personas con personalidad serena son consideradas como personas tranquilas,
                    confiables y con un enfoque en mantener la paz en su entorno.
                    Tienen una actitud colaborativa y disfrutan trabajar en equipo de manera cooperativa.
                  </p>
                  <p className={'conten-dialog-text'}>
                    Pero ten en cuenta que un alto nivel de serenidad puede traer consigo,
                    dificultad para adaptarse rápidamente a los cambios o tomar decisiones rápidas, por lo tanto,
                    si llegas a tener un resultado S, es fundamental equilibrar la estabilidad con la
                    adaptabilidad y ten la disposición de enfrentar nuevos retos.
                  </p>
                  <p className={'conten-dialog-text'}>
                    <strong>
                      Con tu lealtad y perseverancia, el éxito se vuelve inevitable.
                    </strong>
                  </p>
                </div>
                <div className={'backgr-imag'}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={imagS} style={{ width: '90%', height: 'auto', backgroundColor: '#ffffff00' }} />
                  </div>
                </div>
              </div>
              <div className={'conten-grid'}>
                <div className={'conten-dialog-title'}>
                  <h2>Perfil C</h2>
                  <p className={'conten-dialog-text'}>
                    El perfil C, en las pruebas DISC representa el Cumplimiento.
                    Las personas con alto Cumplimiento son perfeccionistas y se preocupan por la calidad y
                    la exactitud en su trabajo. Son minuciosas en sus tareas y tienen una atención especial a
                    los detalles. Suelen ser organizadas, metódicas y estructuradas en su enfoque de trabajo.
                  </p>
                  <p className={'conten-dialog-text'}>
                    Pero ten en cuenta que al tener un alto nivel de cumplimiento puede traer
                    consigo dificultades para adaptarse rápidamente a
                    los cambios o ser flexibles en situaciones imprevistas, por lo tanto,
                    es fundamental equilibrar el Cumplimiento con la flexibilidad y la capacidad de adaptación.
                  </p>
                  <p className={'conten-dialog-text'}>
                    <strong>
                      Estarás atento a los detalles, guiando tu vida al éxito.
                    </strong>
                  </p>
                </div>
                <div className={'backgr-imag'}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={imagC} style={{ width: '90%', height: 'auto', backgroundColor: '#ffffff00' }} />
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalInfoDisc;
