/* eslint-disable new-cap */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useEffect, useRef, useState } from 'react';
import { Snackbar } from '@rmwc/snackbar';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import CardProfile from '../cardProfile/CardProfile';
import Toolbar from '../mdc/Toolbar';
import Footer from '../Footer/Footer';
import Newsletter from '../newsletter/newsletter';
import HamburguerMenu from '../hamburguerMenu/hamburguerMenu';
import ResultProfileDisc from '../resultProfileDisc/ResultProfileDisc';
import ModalTest from './modalTest/ModalTest';
import ModalLoad from './modalLoad/ModalLoad';
import './DashboardDiscResult.css';
import DiscApi from '../../api/DiscApi';
import DataProfile from '../../api/DataProfile';
import ButtonMdc from '../mdc/button/ButtonMdc';
import logo from '../../res/images/bx-logo.png';

const DashboardDiscResult = () => {
  const [openModal, setOpenModal] = useState(true);
  const [result, setResult] = useState([]);
  const [fullnameuser, setFullnameUser] = useState('');
  const [descripcionuser, getDescripcionuser] = useState('');
  const [error, setError] = useState('');
  const [state, setState] = useState(false);
  const [detailsuser, getDetailsuser] = useState([]);
  const [pd, getPd] = useState(0);
  const [pi, getPi] = useState(0);
  const [pc, getPc] = useState(0);
  const [ps, getPs] = useState(0);
  const pdfRef = useRef();
  const itemsMenu = [{
    href: process.env.REACT_APP_EMPLEOS_LOGIN,
    name: 'Bolsa laboral',
  }, {
    href: process.env.REACT_APP_MOODLE_LOGIN,
    name: 'Cursos',
  },
  {
    href: '/home/mis-cursos',
    name: 'Mi espacio',
  }];

  const getDataApi = async () => {
    try {
      if (window.localStorage !== undefined) {
        const mail = localStorage.getItem('mail');
        const response = await DiscApi.postRoutSuggest(mail);
        const user = await DataProfile.getUserDataProfile(mail);
        const descripcion = await DiscApi.getDescripcionProfile(mail);
        const stat = response.data.percentages;
        const find = ((le) => stat.find((e) => (e.letter === le ? e.percentage : 0)));
        getDetailsuser({
          Recomendaciones: descripcion.data.profile.Recomendaciones,
          fortalezas: descripcion.data.profile.fortalezas,
          oportunidades: descripcion.data.profile.oportunidades,
          text: descripcion.data.profile.text,
        });
        getDescripcionuser(descripcion.data.profile.descripcion);
        getPd(find('D'));
        getPi(find('I'));
        getPs(find('S'));
        getPc(find('C'));
        setResult(response.data);
        setFullnameUser(user.data.data[0].userData);
        setOpenModal(true);
      }
    } catch (err) {
      setError(`Hubo un error al Cargar los resultados de las preguntas de la API: ${err}`);
      setState(true);
      setOpenModal(false);
    }
  };
  const downloadPDF = () => {
    const input = pdfRef.current;
    const scale = 4;
    const canvas = document.createElement('canvas');
    canvas.width = input.offsetWidth * scale;
    canvas.height = input.offsetHeight * scale;
    const context = canvas.getContext('2d');
    context.scale(scale, scale);
    html2canvas(input, { scale }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const ancho = 210;
      const nuevoAlto = 140;
      const pdf = new jsPDF({
        orientation: 'l',
        unit: 'mm',
        format: [ancho, nuevoAlto],
        putOnlyUsedFonts: true,
        floatPrecision: 16,
      });
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgY = (pdfWidth - imgWidth * ratio) / 2;
      const imgX = 1;
      pdf.addImage(imgData, 'PNG', imgY, imgX, imgWidth * ratio, imgHeight * ratio);
      const additionalText = 'Resultado de pruebas DISC';
      const textX = 5;
      const textY = 10;
      const textFontSize = 18;
      const logoWidth = 25;
      const logoHeight = 10;
      const pageWidth = pdf.internal.pageSize.getWidth();
      const logoX = pageWidth - logoWidth - 10;
      const logoY = 3;
      pdf.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);
      pdf.setFontSize(textFontSize);
      pdf.setTextColor(92, 92, 92);
      pdf.text(textX, textY, additionalText);
      pdf.save('test-DISC.pdf');
    });
  };

  useEffect(() => {
    getDataApi();
  }, []);

  return (
    <div className={'container-dashboardDisc'}>
      <Toolbar />
      <HamburguerMenu itemsMenu={itemsMenu} />
      {descripcionuser
        ? <div>
          <div ref={pdfRef} className={'container-routSuggest'}>
            <CardProfile
              letter={result.dominantLetter}
              name={fullnameuser.name}
              lastname={fullnameuser.lastName}
              stats={[{
                d: pd, i: pi, s: ps, c: pc,
              }]}
              details={detailsuser}
            />
            <ResultProfileDisc
              letter={result.dominantLetter}
              stats={[{
                d: pd, i: pi, s: ps, c: pc,
              }]}
              descripcion={descripcionuser}
            />
          </div>
          <ButtonMdc
            className={'button-pdf'}
            onClick={downloadPDF}
          >
            Descargar PDF <span className={'mdi mdi-file-pdf-outline'} style={{ fontSize: '15pt' }} />
          </ButtonMdc>
        </div>
        : <div>
          {openModal ? <ModalLoad open /> : <ModalTest open /> }
        </div>}

      <Newsletter />
      <Footer />
      <Snackbar
        open={state}
        onClose={() => setState(false)}
        message={error}
      />
    </div>
  );
};

export default DashboardDiscResult;
