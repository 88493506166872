/* eslint-disable react/jsx-no-undef */
import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle } from '@rmwc/dialog';
import { useHistory } from 'react-router-dom';
import ButtonMdc from '../../mdc/button/ButtonMdc';
import Emojie from '../../../res/images/emoji_angel.png';
import './modalTest.css';

const ModalTest = ({ open }) => {
  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();
  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <div>
      <Dialog
        open={isOpen}
        className={'cardDialog'}
        onClose={() => setIsOpen(false)}
        preventOutsideDismiss
      >
        <div className={'conten'}>
          <img src={Emojie} alt={'emoji'} className={'emoji'} />
          <DialogTitle>Te recomiendo que realices la prueba DISC</DialogTitle>
          <ButtonMdc
            className={'button-res'}
            label={'Realizar prueba DISC'}
            onClick={() => history.push('/test')}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default ModalTest;
