import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, DialogButton,
} from '@rmwc/dialog';
import { Grid, GridCell } from '@rmwc/grid';
import DataApi from '../../api/courses';

const EditCategoryDialog = ({
  open, setOpen, rowData, setKey,
}) => {
  const [id, title, titleCategoria] = rowData;
  const [newPrice, setPrice] = useState({
    0: 0,
  });
  const [status, setStatus] = useState();
  const [course, setCourse] = useState({});

  useEffect(() => {
    setPrice(4534423);
    setStatus('No Visible');
    const getCourse = async () => {
      const response = await DataApi.getCategory(id);
      const { visible, coursecount, prices } = response;
      setCourse({
        visible: visible === 1 ? 'Visible' : 'No Visible',
        coursecount,
      });
      setPrice(prices || { 0: 0 });
    };
    getCourse();
  }, []);
  return (
    <>
      <Dialog
        open={open}
        onClose={(evt) => {
          if (evt.detail.action === 'accept') {
            const estado = status === 'Visible';
            DataApi.updateCategory(id, title, estado, Number(newPrice[0]), newPrice, new Date());
          }
          setKey(Math.random());
          setOpen(false);
        }}
        onClosed={() => {
        }}
      >
        <DialogTitle>Editar Categorias</DialogTitle>
        <DialogContent>
          <Grid>
            <GridCell span={4}>
              <span>Id Categoria: </span><span>{id}</span>
            </GridCell>
            <GridCell span={8}>
              <span>Estado en BX: </span>
              <select
                value={status}
                style={{ display: 'inline-block' }}
                name={'select'}
                onChange={
                  (e) => {
                    setStatus(e.target.value);
                  }
                }
              >
                <option value={'Visible'}>Visible</option>
                <option value={'No Visible'}>No Visible</option>
              </select>
            </GridCell>
          </Grid>
          <Grid>
            <GridCell span={8}>
              <span>Estado en Moodle: </span>
              <select style={{ display: 'inline-block' }} name={'select'} disabled>
                <option value={course.visible}>{course.visible}</option>
              </select>
            </GridCell>
          </Grid>
          <Grid>
            <GridCell span={12}>
              <span>Estado General: </span>
              <select style={{ display: 'inline-block' }} name={'select'} disabled>
                <option value={'Visible'}>No visible</option>
              </select>
            </GridCell>
          </Grid>
          <Grid>
            <GridCell span={12}>
              <span>Titulo:</span> <input style={{ width: '80%' }} type={'text'} value={title} disabled />
            </GridCell>
          </Grid>
          <Grid>
            <GridCell span={4}>
              <span>Número de cursos: </span><span>{course.coursecount}</span>
            </GridCell>
          </Grid>
          <Grid>
            <GridCell span={12}>
              <span>Titulo Categoria:</span> <input type={'text'} value={titleCategoria} disabled />
            </GridCell>
          </Grid>
          <Grid>
            <GridCell span={12}>
              <span>Moneda:</span> <span>COP (Peso Colombiano)</span>
            </GridCell>
          </Grid>
          <Grid>
            <GridCell span={12}>
              <span>Precio sin descuento:</span> <input
                onKeyDown={(e) => {
                  const keyCode = e.key.charCodeAt(0);
                  if (!(keyCode >= 48 && keyCode <= 57) && keyCode !== 66) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setPrice({ ...newPrice, priceNoDiscount: e.target.value });
                }}
                type={'text'}
                placeholder={newPrice[0]}
              />
            </GridCell>
            <GridCell span={12}>
              <span>Precio Total:</span> <input
                onKeyDown={(e) => {
                  const keyCode = e.key.charCodeAt(0);
                  if (!(keyCode >= 48 && keyCode <= 57) && keyCode !== 66) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setPrice({ ...newPrice, 0: e.target.value });
                }}
                type={'text'}
                placeholder={newPrice[0]}
              />
            </GridCell>
            <GridCell span={12}>
              <span>Precio cuota #1:</span> <input
                onKeyDown={(e) => {
                  const keyCode = e.key.charCodeAt(0);
                  if (!(keyCode >= 48 && keyCode <= 57) && keyCode !== 66) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setPrice({ ...newPrice, 1: e.target.value });
                }}
                type={'text'}
                placeholder={newPrice[1]}
              />
            </GridCell>
            <GridCell span={12}>
              <span>Precio cuota #2:</span> <input
                onKeyDown={(e) => {
                  const keyCode = e.key.charCodeAt(0);
                  if (!(keyCode >= 48 && keyCode <= 57) && keyCode !== 66) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setPrice({ ...newPrice, 2: e.target.value });
                }}
                type={'text'}
                placeholder={newPrice[2]}
              />
            </GridCell>
            <GridCell span={12}>
              <span>Precio cuota #3:</span> <input
                onKeyDown={(e) => {
                  const keyCode = e.key.charCodeAt(0);
                  if (!(keyCode >= 48 && keyCode <= 57) && keyCode !== 66) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setPrice({ ...newPrice, 3: e.target.value });
                }}
                type={'text'}
                placeholder={newPrice[3]}
              />
            </GridCell>
            <GridCell span={12}>
              <span>Precio cuota #4:</span> <input
                onKeyDown={(e) => {
                  const keyCode = e.key.charCodeAt(0);
                  if (!(keyCode >= 48 && keyCode <= 57) && keyCode !== 66) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setPrice({ ...newPrice, 4: e.target.value });
                }}
                type={'text'}
                placeholder={newPrice[4]}
              />
            </GridCell>
          </Grid>

        </DialogContent>
        <DialogActions>
          <DialogButton action={'close'}>Cacelar</DialogButton>
          <DialogButton action={'accept'} isDefaultAction>
            Guardar
          </DialogButton>
        </DialogActions>
      </Dialog>
    </>

  );
};

export default EditCategoryDialog;
