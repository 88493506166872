import React from 'react';
import Toolbar from '../mdc/Toolbar';
import Slider from '../slider/Slider';
import ImgChildren from '../slider/ImgChildren';
import SearchCandidates from '../searchCandidates/searchCandidates';
import mainSlide from '../../res/images/banner-principal.jpg';
import hexa from '../../res/images/hexa.png';
import Events from '../calendarevents/Events';
import Newsletter from '../newsletter/newsletter';
import Footer from '../Footer/Footer';
import Enterprises from '../graduates/Enterprises';
import ProgramsEnterprise from '../ProgramsEnterprise/ProgramsEnterprise';
import HamburguerMenu from '../hamburguerMenu/hamburguerMenu';

const HomeEnterprise = () => {
  const firstText = 'Encuentra un equipo con';
  const secondText = 'habilidades específicas';
  const itemsMenu = [{
    href: process.env.REACT_APP_EMPLEOS_LOGIN,
    name: 'BX Labs',
  }, {
    href: process.env.REACT_APP_EMPLEOS_LOGIN,
    name: 'Bolsa laboral',
  }, {
    href: process.env.REACT_APP_MOODLE_LOGIN,
    name: 'Cursos',
  },
  {
    href: process.env.REACT_APP_EMPLEOS_LOGIN,
    name: 'Planes',
  }];

  return (
    <>
      <Toolbar />
      <HamburguerMenu itemsMenu={itemsMenu} />
      <Slider firstText={firstText} secondText={secondText} mainSlide={mainSlide} buttonText={'¡Encuentra tu equipo!'} bottom={false}>
        <ImgChildren imgSrc={hexa} />
      </Slider>
      <SearchCandidates />
      <ProgramsEnterprise />
      <Events />
      <Enterprises />
      <Newsletter />
      <Footer />
    </>

  );
};

export default HomeEnterprise;
