import { api, escalateError } from './index';

export default class DiscApi {
  static async getQuestionsDisc() {
    try {
      return await api.get('/testdisc/questions');
    } catch (error) {
      escalateError(error);
      return false;
    }
  }

  static async putDiscUser(email, leadId) {
    try {
      return await api.put('/testdisc/answer', {
        email,
        leadId,
      });
    } catch (error) {
      escalateError(error);
      return false;
    }
  }

  static async postAnswerDisc(data) {
    const body = {
      email: data.email,
      leadId: data.leadId,
      characteristics: data.answers,

    };
    try {
      return await api.post('/testdisc/answer', body);
    } catch (error) {
      escalateError(error);
      return false;
    }
  }

  static async postRoutSuggest(email) {
    const body = {
      email,
    };
    try {
      return await api.post('/testdisc/resultProfile', body);
    } catch (error) {
      escalateError(error);
      return false;
    }
  }

  static async getTimeTest(email) {
    try {
      return await api.get(`/testdisc/timeTest?email=${email}`);
    } catch (error) {
      escalateError(error);
      return false;
    }
  }

  static async getDescripcionProfile(email) {
    try {
      return await api.get(`/testdisc/profile?email=${email}`);
    } catch (error) {
      escalateError(error);
      return false;
    }
  }
}
