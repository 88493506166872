import React from 'react';

const ImgChildren = ({ imgSrc }) => (
  <div className={'hexaImg'}>
    <div className={'__hexaImg'}>
      <img alt={'BX Logo'} src={imgSrc} />
    </div>
  </div>
);

export default ImgChildren;
