/* eslint-disable import/order */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import ButtonMdc from '../../mdc/button/ButtonMdc';
import BackImag from '../../../res/images/Ellipse8.png';
import './modalWelcome.css';
import { useHistory } from 'react-router-dom';

const ModalWelcome = () => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest('.conten-all-dialog')) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  const openCarousel = () => {
    setIsOpen(true);
  };
  return (
    <>
      <ButtonMdc
        className={'button-tutorial'}
        onClick={() => openCarousel(true)}
      >Empieza Aquí &nbsp;
        <span className={'mdi mdi-cursor-default-click'} style={{ color: 'white', fontSize: '15pt' }} />
      </ButtonMdc>
      <Dialog
        maxWidth={'xl'}
        fullWidth
        open={isOpen}
      >
        <DialogContent style={{ padding: '0' }}>
          <div className={'conten-all-dialog'}>
            <div className={'conten-dialog'}>
              <h2>¿Por qué una <br /> <strong>prueba de personalidad?</strong></h2>
              <p className={'conten-text'}>
                Para nosotros es muy importante que desarrolles tus habilidades técnicas, pero también queremos que desarrolles tus habilidades
                socioemocionales y si conocemos un poco más de ti y sobre tu personalidad, sabremos cómo hacerlo. Cuando respondas este cuestionario,
                obtendremos un perfil de tu personalidad y te crearemos una ruta de aprendizaje en habilidades socioemocionales
                que te permita potenciar tu talento.
              </p>

              <p className={'subtitle'}>Empieza tu prueba de</p>
              <p className={'subtitle-personaliti'}>PERSONALIDAD</p>
              <ButtonMdc
                className={'button-ingresar'}
                label={'ingresar'}
                onClick={() => history.push('/test')}
              />
            </div>
            <div className={'backgr-imag'}>
              <span className={'close-x mdi mdi-close'} onClick={() => setIsOpen(false)} />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={BackImag} style={{ height: '500px', backgroundColor: '#ffffff00' }} />
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalWelcome;
