import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LoginRoutesContext from '../contexts/login-routes-context';
import firebase from '../firebase';
import DataApi from '../api/LoginApi';

const LoginRoutesData = ({ children }) => {
  const setCookie = require('set-cookie');
  const [firebaseAuth] = useState(firebase.auth());
  const [, setUser] = useState(firebaseAuth ? firebaseAuth.currentUser : null);
  const [isEnterpriseContext, setIsEnterpriseContext] = useState(false);
  const [role, setRole] = useState('normal');
  const [userEmail, setUserEmail] = useState('');

  const share = {
    isEnterpriseContext,
    userEmail,
    setIsEnterpriseContext,
    setUserEmail,
    role,
    setRole,
  };

  const setCookies = (todos) => {
    // eslint-disable-next-line camelcase
    const { auth_cookie, logged_in_cookie } = todos.data[0].cookie;
    // CREATE COOKIE
    setCookie(
      `wordpress_${process.env.REACT_APP_HASH_COOKIE}`,
      auth_cookie,
      {
        domain: 'bx.expert',
        path: '/wp-admin',
      },
    );
    setCookie(
      `wordpress_${process.env.REACT_APP_HASH_COOKIE}`,
      auth_cookie,
      {
        domain: 'bx.expert',
        path: '/wp-content/plugins',
      },
    );
    setCookie(
      `wordpress_logged_in_${process.env.REACT_APP_HASH_COOKIE}`,
      logged_in_cookie,
      {
        domain: 'bx.expert',
        path: '/',
      },
    );
  };

  firebaseAuth.onAuthStateChanged(setUser);

  useEffect(() => {
    if (firebaseAuth.currentUser) {
      const { email } = firebaseAuth.currentUser;
      firebase.analytics().logEvent('login Process');
      DataApi.getUserEmailSession(email, 'token')
        .then((todos) => {
          // eslint-disable-next-line camelcase
          if (todos.data[0].cookie) {
            setCookies(todos);
          }
          localStorage.setItem('userID', todos?.data[0]?.userId);
          localStorage.setItem('isEnterprise', todos?.data[0]?.userData?.isEnterprise);
          setIsEnterpriseContext(todos?.data[0]?.userData?.isEnterprise);
          setUserEmail(todos?.data[0]?.lastUser);
          if (localStorage.getItem('token')) {
            setRole(todos?.data[0]?.role);
          }
        });
    }
  }, [firebaseAuth.currentUser]);

  return (
    <LoginRoutesContext.Provider value={share}>
      {children}
    </LoginRoutesContext.Provider>
  );
};

LoginRoutesData.propTypes = { children: PropTypes.node };
export default LoginRoutesData;
