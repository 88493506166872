import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const DiscStateContext = createContext();
const DiscStateContextProvider = ({ children }) => {
  const [buttonState, setButtonState] = useState(false);
  const [characteristics, setCharacteristics] = useState([]);

  return (
    <DiscStateContext.Provider value={{
      buttonState, setButtonState, characteristics, setCharacteristics,
    }}
    >
      {children}
    </DiscStateContext.Provider>
  );
};

DiscStateContextProvider.propTypes = {
  children: PropTypes.oneOfType(
    [PropTypes.object, PropTypes.arrayOf(PropTypes.element)],
  ).isRequired,
};

export default DiscStateContextProvider;
