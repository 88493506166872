/* eslint-disable react/jsx-no-undef */
import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle } from '@rmwc/dialog';
import Loader from '../../loader/loader';
import './modalLoad.css';

const ModalLoad = ({ open }) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <div>
      <Dialog
        open={isOpen}
        className={'cardDialog'}
        onClose={() => setIsOpen(false)}
        preventOutsideDismiss
      >
        <div className={'conten'}>
          <Loader />
          <DialogTitle>Cargando...</DialogTitle>
        </div>
      </Dialog>
    </div>
  );
};

export default ModalLoad;
