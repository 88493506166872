import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, DialogButton,
} from '@rmwc/dialog';
import { Grid, GridCell } from '@rmwc/grid';
import DataApi from '../../api/courses';

const EditCourseDialog = ({
  open, setOpen, rowData, setKey,
}) => {
  const [id, title, idCategoria, titleCategoria] = rowData;
  const [newPrice, setPrice] = useState(0);
  const [status, setStatus] = useState();
  const [course, setCourse] = useState({});

  useEffect(() => {
    setPrice(4534423);
    setStatus('No Visible');
    const getCourse = async () => {
      const response = await DataApi.getCategory(id);
      const { visible } = response;
      setCourse({
        visible: visible === 1 ? 'Visible' : 'No Visible',
      });
    };
    getCourse();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={(evt) => {
          if (evt.detail.action === 'accept') {
            const estado = status === 'Visible';
            DataApi.updateCourse(id, estado, Number(newPrice), new Date());
          }
          setOpen(false);
          setKey(Math.random());
        }}
        onClosed={() => {}}
      >
        <DialogTitle>Editar curso</DialogTitle>
        <DialogContent>
          <Grid>
            <GridCell span={4}>
              <span>Id Curso: </span><span>{id}</span>
            </GridCell>
            <GridCell span={8}>
              <span>Estado en BX: </span>
              <select
                value={status}
                style={{ display: 'inline-block' }}
                name={'select'}
                onChange={
                  (e) => {
                    setStatus(e.target.value);
                  }
                }
              >
                <option value={'Visible'}>Visible</option>
                <option value={'No Visible'}>No Visible</option>
              </select>
            </GridCell>
          </Grid>
          <Grid>
            <GridCell span={8}>
              <span>Estado en Moodle: </span>
              <select style={{ display: 'inline-block' }} name={'select'} disabled>
                <option value={course.visible}>{course.visible}</option>
              </select>
            </GridCell>
          </Grid>
          <Grid>
            <GridCell span={12}>
              <span>Estado General: </span>
              <select style={{ display: 'inline-block' }} name={'select'} disabled>
                <option value={'Visible'}>No visible</option>
              </select>
            </GridCell>
          </Grid>
          <Grid>
            <GridCell span={12}>
              <span>Titulo:</span> <input style={{ width: '80%' }} type={'text'} value={title} disabled />
            </GridCell>
          </Grid>
          <Grid>
            <GridCell span={4}>
              <span>Id Categoria: </span><span>{idCategoria}</span>
            </GridCell>
          </Grid>
          <Grid>
            <GridCell span={12}>
              <span>Titulo Categoria:</span> <input type={'text'} value={titleCategoria} disabled />
            </GridCell>
          </Grid>
          <Grid>
            <GridCell span={12}>
              <span>Moneda:</span> <span>COP (Peso Colombiano)</span>
            </GridCell>
          </Grid>
          <Grid>
            <GridCell span={12}>
              <span>Precio:</span> <input
                onKeyDown={(e) => {
                  const keyCode = e.key.charCodeAt(0);
                  if (!(keyCode >= 48 && keyCode <= 57) && keyCode !== 66) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setPrice(e.target.value);
                }}
                type={'text'}
                placeholder={newPrice}
              />
            </GridCell>
          </Grid>

        </DialogContent>
        <DialogActions>
          <DialogButton action={'close'}>Cacelar</DialogButton>
          <DialogButton action={'accept'}>
            Guardar
          </DialogButton>
        </DialogActions>
      </Dialog>
    </>

  );
};

export default EditCourseDialog;
