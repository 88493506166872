import React, { useState } from 'react';
import './AdminLogin.css';
import { Button } from '@rmwc/button';
import { useHistory } from 'react-router-dom';
import firebase from '../../firebase';
import DataApi from '../../api/LoginApi';

const LoginAdmin = ({ setRole }) => {
  const history = useHistory();
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [isValid, setIsValid] = useState({ email: true, password: true });
  const handlerInput = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const addUserSession = (mail, token) => {
    firebase.analytics().logEvent('login Process');
    if (!token || token.length <= 0) return;
    DataApi.getUserEmailSession(mail, token)
      .then((todos) => {
        localStorage.setItem('userID', todos?.data[0]?.userId);
        localStorage.setItem('isEnterprise', todos?.data[0]?.userData?.isEnterprise);
        setTimeout(() => {
          setRole(todos?.data[0]?.role);
          history.push('/bx-admin/dashboard');
        }, 1000);
      }).catch(() => {
      });
  };

  const validateForm = () => {
    const { email, password } = credentials;
    setIsValid({
      email: !!email,
      password: !!password,
    });
    return !!((email && password));
  };

  const handlerForm = () => {
    if (!validateForm()) {
      return;
    }
    const { email, password } = credentials;
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(async () => {
        await firebase.auth().currentUser.getIdToken().then((userToken) => {
          localStorage.setItem('token', userToken);
          addUserSession(email, userToken);
        });
        firebase.analytics().logEvent('Login Success');
      })
      .catch(() => {
        /* setState(true);
        setTextMessage('La contraseña o el usuario son incorrectos'); */
        firebase.analytics().logEvent('User or password is incorrect');
      });
  };
  return (
    <div className={'wrapper'}>
      <div className={'cardLogin'}>
        <h4> Sesión</h4>
        <label htmlFor={'email'} className={isValid.email ? '' : 'wrong'}>
          Email
          <input id={'emailAdmin'} name={'email'} onChange={handlerInput} />
        </label>
        <label htmlFor={'password'} className={isValid.password ? '' : 'wrong'}>
          Contraseña
          <input type={'password'} id={'passwordAdmin'} name={'password'} onChange={handlerInput} />
        </label>
        <Button style={{ backgroundColor: '#234', minWidth: '50%' }} onClick={handlerForm} name={'login'}>Log In</Button>
      </div>
    </div>
  );
};

export default LoginAdmin;
