import React from 'react';
import { useDrag } from 'react-dnd';
import './CardDrag.css';

const CardDrag = ({ type, text, pointer }) => {
  const [{ opacity }, dragRef] = useDrag(() => ({
    type,
    item: { text },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.8 : 1,
    }),
  }));

  return (
    <div className={'carddrag'} ref={dragRef} style={{ opacity, pointerEvents: pointer }}>
      {text}
    </div>
  );
};

export default CardDrag;
