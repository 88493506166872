/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ProgramsImg from '../../res/images/programsenterprise.png';

import './ProgramsEnterprise.css';

const ProgramsEnterprise = () => (
  <section className={'programs-enterprise'}>
    <img alt={'programs'} className={'programs-enterprise-img'} src={ProgramsImg} />
    <div className={'content'}>
      <h3 className={'_title'}>Un gran equipo logra grandes resultados</h3>
      <div className={'programs-separator'} />
      <a href={process.env.REACT_APP_MOODLE_LOGIN}>Ver Programas</a>
    </div>
  </section>
);

export default ProgramsEnterprise;
