import React from 'react';
// import premioImg from '../../res/images/premio.png';
import './CardEvent.css';

const CardEvent = (props) => {
  const {
    title, place, hour, link,
  } = props;
  return (
    <div className={'card-events'}>
      <div>
        <img
          alt={'present'}
          src={'https://static-cdn.jtvnw.net/jtv_user_pictures/0198ce89-bbf4-46ca-a70f-7b6fbe0f4ccf-profile_image-70x70.png'}
        />
      </div>
      <div className={'events-info'}>
        <h3>{title}</h3>
        <h4>Evento tecnológico</h4>
        <div className={'events-moreinfo'}>
          <div className={'moreinfo'}>
            <p>{place}</p>
            <p>{hour}</p>
          </div>
          <a href={link} target={'_blank'} rel={'noreferrer'}>
            <div>
              Ver más
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CardEvent;
