import React from 'react';
import Calendar from './Calendar';
import CardEvent from './CardEvent';
import Separator from './Separator';

import './Events.css';

const Events = () => (
  <section className={'events-section'}>
    <div className={'_calendar'}>
      <div className={'calendar'}>
        <h2>Próximos eventos</h2>
        <Calendar />
      </div>
    </div>
    <div className={'events'}>
      <h2>Jueves 23 de Marzo</h2>
      <CardEvent title={'BoyaConf'} place={'Twitch'} hour={'5:30 P.M'} link={'https://twitch.tv/boyaconf'} />
      <Separator />
    </div>
  </section>
);

export default Events;
