/* eslint-disable react/no-array-index-key */
import React from 'react';
import './Calendar.css';

const Calendar = () => {
  const day = new Date();
  const actualYear = day.getFullYear();

  const locale = 'es';

  const intlForMonths = new Intl.DateTimeFormat(locale, { month: 'long' });
  const months = [new Date().getMonth()];

  const today = new Date().getDate();
  const weekDays = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];

  const calendar = months.map((monthIndex) => {
    const monthName = intlForMonths.format(new Date(actualYear, monthIndex));
    const nextMonthIndex = (monthIndex + 1) % 12;
    const daysOfMonth = new Date(actualYear, nextMonthIndex, 0).getDate();
    const startsOnF = new Date(actualYear, monthIndex, 1).getDay();
    const startsOn = startsOnF === 0 ? 7 : startsOnF;
    return {
      daysOfMonth,
      monthName,
      startsOn,
    };
  });

  return (
    <div className={'calendarandevents'}>{
      calendar.map(({ daysOfMonth, monthName, startsOn }) => {
        const days = [...Array(daysOfMonth).keys()];
        const firstDayAttributes = 'first-day';
        const htmlDaysName = weekDays
          .map((dayName, index) => <li key={`${index}-${dayName}`} className={'day-name'}>{dayName}</li>);
        const htmlDays = days
          .map(
            (day, index) => {
              const isToday = (day + 1) === today ? 'day today' : 'day';
              return (
                <li key={`${index}-${day}`} className={index === 0 ? `${firstDayAttributes}  ${isToday}` : `${isToday}`} style={index === 0 ? { '--first-day-start': startsOn } : {}}>
                  {day + 1}
                </li>);
            },
          );
        return (
          <>
            <h2 className={'month-title'}>{monthName}</h2>
            <ol>{htmlDaysName}{htmlDays}</ol>
          </>);
      })
    }
    </div>
  );
};

export default Calendar;
