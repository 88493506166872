/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import BackImag from '../../../res/images/Ellipse8.png';
import Tutorial from '../../../res/images/tutorial.gif';
import './modalTutorial.css';

const ModalTutorial = ({ open }) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(open);
  }, [open]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest('.conten-all-dialog')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);
  return (
    <Dialog
      maxWidth={'xl'}
      fullWidth
      open={isOpen}
    >
      <DialogContent style={{ padding: '0' }}>
        <div className={'conten-all-dialog'}>
          <div className={'conten-dialog-tutorial'}>
            <h2 style={{
              color: 'white', margin: '5px',
            }}
            >Inicia tu Test
            </h2>
            <p className={'conten-text-tutorial'}>
              Ten en cuenta que este cuestionario no es un examen,
              entonces no hay respuestas correctas e incorrectas.
              Simplemente, queremos saber más de ti, por eso te preguntaremos qué tanto te identificas con ciertas características en el día a día.
              -Lee atentamente cada una de las características.
              Si alguna no es clara para ti, haz clic en XX para ver la definición.
            </p><img src={Tutorial} style={{ borderRadius: '15px' }} />
            <p className={'ul-text-tutorial'}>
              <ul>
                <li>
                  <span className={'mdi mdi-circle-medium'} style={{ color: '#FFA500' }} />
                  A cada característica, solo le podrás asignar una valoración.
                </li>
                <li>
                  <span className={'mdi mdi-circle-medium'} style={{ color: '#FFA500' }} />
                  Si tienes alguna duda, podrás regresar a la pregunta anterior.
                </li>
                <li>
                  <span className={'mdi mdi-circle-medium'} style={{ color: '#FFA500' }} />
                  Tómate el tiempo que necesites para contestar las preguntas.
                </li>
              </ul>
            </p>
          </div>
          <div className={'backgr-imag'}>
            <span className={'close-x mdi mdi-close'} onClick={() => setIsOpen(false)} />
            <div style={{
              height: '94.2%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end',
            }}
            >
              <img src={BackImag} style={{ height: '500px', backgroundColor: '#ffffff00' }} />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalTutorial;
