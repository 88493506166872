import React, { useState, useEffect } from 'react';
import './ResultProfileDisc.css';
import DiscApi from '../../api/DiscApi';
import ProgressBar from '../GraphicDics/ProgressBar';
import DonutGraphic from '../GraphicDics/DonutGraphic ';
import ModalRouts from './modalRouts/ModalRouts';

const ResultProfileDisc = ({ stats, letter, descripcion }) => {
  const [datePresentation, setDatePresentation] = useState('');
  const [dateNext, setDateNext] = useState('');
  useEffect(() => {
    const getMyCourses = async () => {
      if (window.localStorage !== undefined) {
        const email = localStorage.getItem('mail');
        const response = await DiscApi.getTimeTest(email);
        setDateNext(response.data.daysToNextTest);
        setDatePresentation(response.data.formatDate);
      }
    };
    getMyCourses();
  }, []);
  return (
    <div className={'container-result-disc'}>
      <div className={'container-time'}>
        <div className={'container-time-one'}>
          <h5>PRUEBA DISC</h5>
          <h4>{datePresentation}</h4>
        </div>
        <div className={'container-reload'}><p>Tiempo de recarga: {dateNext} días</p></div>
      </div>
      <div className={'linea'} />
      <div className={'container-graphics'}>
        <div className={'container-donut'}>
          <DonutGraphic
            letter={letter}
            d={stats[0].d?.percentage || 0}
            i={stats[0].i?.percentage || 0}
            s={stats[0].s?.percentage || 0}
            c={stats[0].c?.percentage || 0}
          />
        </div>
        <div className={'container-progres'}>
          <ProgressBar leter={'D'} value={stats[0].d?.percentage || 0} color={'#1496F9'} />
          <ProgressBar leter={'I'} value={stats[0].i?.percentage || 0} color={'#E32256'} />
          <ProgressBar leter={'S'} value={stats[0].s?.percentage || 0} color={'#FFB126'} />
          <ProgressBar leter={'C'} value={stats[0].c?.percentage || 0} color={'#35CCDB'} />
          <ModalRouts letter={letter} />
        </div>
      </div>
      <div className={'container-text-res'}>
        <p>{descripcion.toString().split('.').map((e, i) => (i === 0
          ? <b>{e}.</b>
          : (i % 2) === 0 && i !== (descripcion.toString().split('.').length - 1)
            ? <>{e}.</>
            : i === (descripcion.toString().split('.').length - 1)
              ? <>{e}</>
              : <>{e}.<br /><br /></>
        ))}
        </p>
      </div>
    </div>
  );
};

export default ResultProfileDisc;
