/* eslint-disable max-len */
import React, { useState } from 'react';
import './CardCourse.css';
import bookIcon from '../../res/images/bookIcon.svg';
import clockIcon from '../../res/images/clockIcon.svg';
import arrowRigth from '../../res/images/arrowrigth.svg';
import cart from '../../res/images/cart.svg';
import ProductShop from '../shop/ProductShop';
import OrdersApi from '../../api/orders';
import ButtonGo from './ButtonGo';

const CardCourse = ({
  productId, color, imgSrc, mainText, duration, description,
}) => {
  const [open, setOpen] = useState(false);
  const [finishedPay, setFinishedPay] = useState(true);
  useState(() => {
    const validationPayment = async () => {
      if (localStorage !== undefined) {
        const userID = localStorage.getItem('userID');
        const response = await OrdersApi.validationPayment(userID, productId);
        setFinishedPay(response.finishPayment);
      }
    };
    validationPayment();
  });
  return (
    <div className={`cardCourse cardCourse-${color}`}>
      <div className={'__cardCourseCart'}>
        <img alt={'course'} src={cart} />
      </div>
      <div className={'__cardCourseRow1'}>
        <div className={'cardImg'}>
          <img alt={'course'} src={imgSrc} />
        </div>
        <div className={'mainText'}>
          <h3>{mainText}</h3>
        </div>
      </div>
      <div className={'__cardCourseRow2'}>
        <div className={'cardInformation'}>
          <ul>
            <li><img alt={'icon-user'} className={'icon-user'} src={bookIcon} /> 9 horas semanales teóricas y de laboratorio</li>
            <li><img alt={'icon-user'} className={'icon-user'} src={clockIcon} /> Duración: {duration} Semanas</li>
          </ul>
        </div>
        <div className={'cardAction'}>
          {!finishedPay ? (<ButtonGo setOpen={setOpen} />)
            : (
              <a className={'callAction-courses'} target={'_blank'} rel={'noreferrer'} href={process.env.REACT_APP_MOODLE_LOGIN}>
                <div className={'__callAction'}>
                  <li>Ir al curso<img alt={'icon-user'} className={'icon-user'} src={arrowRigth} /></li>
                </div>
              </a>)}
        </div>
      </div>
      <ProductShop open={open} setOpen={setOpen} productId={productId} title={mainText} description={description} />
    </div>
  );
};

export default CardCourse;
