import React from 'react';
import DrawerWrapper from '../mdc/drawer/DrawerWrapper';
import Toolbar from '../mdc/Toolbar';
import SiteBody from '../mdc/SiteBody';

const NotFound = () => (
  <>
    <DrawerWrapper />
    <Toolbar />
    <SiteBody>
      <h1>Not Found</h1>
    </SiteBody>
  </>
);

export default NotFound;
