import React, { useState } from 'react';
import { TabBar, Tab } from '@rmwc/tabs';
import LateralMenu from './LateralMenu';
import TableCourses from './TableCourses';
import TableCategories from './TableCategories';

const Admin = () => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <div style={{ display: 'flex' }}>
      <LateralMenu />
      <div style={{ margin: '50px' }}>
        <h3 style={{ margin: '5px' }}>Cursos</h3>
        <TabBar
          activeTabIndex={activeTab}
          onActivate={(evt) => setActiveTab(evt.detail.index)}
          style={{ maxWidth: '500px' }}
        >
          <Tab>Rutas</Tab>
          <Tab>Cursos</Tab>
        </TabBar>
        {activeTab === 0 && <TableCategories />}
        {activeTab === 1 && <TableCourses />}
      </div>
    </div>);
};

export default Admin;
