/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import Axios from 'axios';

const EmailRecovery = () => {
  const [email, setEmail] = useState('');
  const [err, setErr] = useState(false);
  const [disable, setDisable] = useState(false);
  const [newMail, setNewMail] = useState(false);
  const changeMail = (e) => {
    const { value } = e.target;
    setEmail(value);
  };
  const validateMail = () => {
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!regex.test(email)) {
      setErr(true);
      return false;
    }
    return true;
  };

  const sendMail = () => {
    if (validateMail()) {
      try {
        Axios.post(`${process.env.REACT_APP_BASE_URL}/recovery`, { email });
      // eslint-disable-next-line no-empty
      } catch (error) { }
      document.getElementsByName('mailRecovery')[0].value = '';
      setDisable(true);
      setErr(false);
      setNewMail(true);
      const SECONDS = 1000;
      setTimeout(() => {
        setDisable(false);
        setNewMail(false);
      }, 30 * SECONDS);
    }
  };
  return (
    <>
      <input name={'mailRecovery'} onChange={changeMail} placeholder={'Correo eléctronico'} />
      <label className={`invalidEmail ${err ? 'invalidEmail-show' : ''}`}>**Correo inválido</label>
      <label className={`invalidEmail ${newMail ? 'invalidEmail-show' : ''}`}>Si no te llegó el correo vuelve a intentarlo en 30 segundos</label>
      <a className={disable ? 'sendLink-disable' : ''} onClick={sendMail}>Enviar link</a>
    </>
  );
};

export default EmailRecovery;
