import React, { useState, useEffect } from 'react';
import { SimpleDataTable } from '@rmwc/data-table';
import Axios from 'axios';
import EditCourseDialog from './EditCourseDialog';

const TableCourses = () => {
  const [courses, setCourses] = useState([]);
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [key, setKey] = useState(Math.random());
  useEffect(() => {
    const getCourses = async () => {
      const data = await Axios.get(`${process.env.REACT_APP_BASE_URL}/courses`);
      const rows = data.data.map((course) => {
        const {
          id, category, nameCategory, fullname, visible,
        } = course;
        const isVisible = visible === 0 ? 'No visible' : 'Visible';
        return [id, fullname, category, nameCategory, 'No especificado', isVisible, '1234/05/06', 'editar'];
      });
      setCourses(rows);
    };
    getCourses();
  }, []);
  return (
    <>
      <SimpleDataTable
        style={{ maxHeight: '80vh' }}
        getRowProps={(row, index) => (index % 2 === 1 ? {
          activated: true,
          onClick: () => {
            setRowData(row);
            setOpen(true);
          },
        } : {
          onClick: () => {
            setRowData(row);
            setOpen(true);
          },
        })}
        getCellProps={(cell, index, isHead) => {
          const props = { isNumeric: index > 0, style: undefined };

          if (!isHead && index === 4) {
            const color = cell === 'No especificado' ? '#D6352D' : '#35682D';
            return { ...props, style: { color } };
          }
          if (!isHead && index === 7) {
            return { ...props, style: { color: '#44f', cursor: 'pointer' } };
          }
          return props;
        }}
        headers={[['ID', 'Nombre', 'Id Categoria', 'Categoria', 'Precio', 'Estado', 'Última modificación', 'Acciones']]}
        data={courses}
      />
      {/* eslint-disable max-len */}
      {open && <EditCourseDialog key={key} rowData={rowData} open={open} setOpen={setOpen} setKey={setKey} />}
    </>
  );
};

export default TableCourses;
