/* eslint-disable react/jsx-no-undef */
import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle } from '@rmwc/dialog';
import { useHistory } from 'react-router-dom';
import ButtonMdc from '../../mdc/button/ButtonMdc';
import Emojie from '../../../res/images/emoji_2.png';
import './modaldaysTest.css';

const ModalDaysTest = ({ open, days }) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <div>
      <Dialog
        open={isOpen}
        className={'cardDialog'}
        onClose={() => setIsOpen(false)}
        preventOutsideDismiss
      >
        <div className={'conten'}>
          <img src={Emojie} alt={'emoji'} className={'emoji'} />
          <DialogTitle>Ya has resuelto la prueba DISC</DialogTitle>
          <text>Días Restantes <b>{days}</b> para que puedas realizar la prueba</text>
          <ButtonMdc
            className={'button-res'}
            label={'ver Resultado'}
            onClick={() => history.push('/home/mis-cursos')}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default ModalDaysTest;
