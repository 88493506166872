/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable camelcase */
import React, {
  useMemo, useState, useContext, useEffect,
} from 'react';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import Recaptcha from 'react-recaptcha';
import { TextField } from '@rmwc/textfield';
import SwitchSelector from 'react-switch-selector';
import { Dialog, DialogContent } from '@rmwc/dialog';
import { Snackbar } from '@rmwc/snackbar';
import { useHistory } from 'react-router-dom';
import { Fab } from '@rmwc/fab';
import ButtonMdc from '../mdc/button/ButtonMdc';
import DataApi from '../../api/LoginApi';
import firebase from '../../firebase';
import Utils from '../../utils/Utils';
import Loader from '../loader/loader';
import BXlogin from '../../res/images/login-logo.svg';
import Register from '../register/register';
import LoginRoutesContext from '../../contexts/login-routes-context';
import './login.css';

const LoginForm = () => {
  const history = useHistory();
  let recaptchaInstance = '';
  const [limitRetry, setLimitRetry] = useState(0);
  const [ready, setReady] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [optionSelected, setOptionSelected] = useState('iniciar');
  const [state, setState] = useState(false);
  const [textMessage, setTextMessage] = useState('');
  const { setUserEmail, setIsEnterpriseContext } = useContext(LoginRoutesContext);
  const setCookie = require('set-cookie');

  let token;
  const options = [
    {
      label: 'Iniciar sesión',
      value: 'iniciar',
      innerHeight: 50,
    },
    {
      label: 'Registrarse',
      value: 'registrarse',

    },
  ];
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const leadId = queryParams.get('leadId');
    if (leadId) {
      setOptionSelected('registrarse');
    }
  }, [optionSelected]);

  const onChange = (newValue) => {
    setOptionSelected(newValue);
  };

  const setCookies = (todos) => {
    const { auth_cookie, logged_in_cookie } = todos.data[0].cookie;
    // CREATE COOKIE
    setCookie(
      `wordpress_${process.env.REACT_APP_HASH_COOKIE}`,
      auth_cookie,
      {
        domain: 'bx.expert',
        path: '/wp-admin',
      },
    );
    setCookie(
      `wordpress_${process.env.REACT_APP_HASH_COOKIE}`,
      auth_cookie,
      {
        domain: 'bx.expert',
        path: '/wp-content/plugins',
      },
    );
    setCookie(
      `wordpress_logged_in_${process.env.REACT_APP_HASH_COOKIE}`,
      logged_in_cookie,
      {
        domain: 'bx.expert',
        path: '/',
      },
    );
  };

  const initialSelectedIndex = options.findIndex(({ value }) => value === 'iniciar');

  useMemo(() => {
    setEmailError('');
    // eslint-disable-next-line
  }, [email]);

  useMemo(() => {
    setPasswordError('');
    // eslint-disable-next-line
  }, [password]);

  const addUserSession = (mail, token) => {
    firebase.analytics().logEvent('login Process');
    if (!token || token.length <= 0) return;
    setReady(false);
    DataApi.getUserEmailSession(mail, token)
      .then((todos) => {
        if (todos.data[0].cookie) {
          setCookies(todos);
        }
        localStorage.setItem('userID', todos?.data[0]?.userId);
        localStorage.setItem('isEnterprise', todos?.data[0]?.userData?.isEnterprise);
        setIsEnterpriseContext(todos?.data[0]?.userData?.isEnterprise);
        setUserEmail(todos?.data[0]?.lastUser);
        // eslint-disable-next-line no-unused-expressions
        (todos?.data[0]?.userData?.isEnterprise === true || todos?.data[0].leadId !== undefined)
          ? history.push('/home')
          : history.push('/hometest');
      }).catch(() => {
      });
  };

  const validateFields = () => {
    if (email.length <= 0) {
      setEmailError('Escriba un correo electronico');
      return false;
    }
    if (password.length <= 0) {
      setPasswordError('La contraseña es requerida');
      return false;
    }
    if (!Utils.validateEmail(email)) {
      setEmailError('El correo electrónico no es válido');
      return false;
    }
    return true;
  };

  const login = () => {
    if (validateFields()) {
      if (limitRetry <= 3) {
        setLimitRetry(limitRetry + 1);
        firebase.auth().signInWithEmailAndPassword(email, password)
          .then(async () => {
            await firebase.auth().currentUser.getIdToken().then((userToken) => {
              token = userToken;
              localStorage.setItem('token', token);
            });
            firebase.analytics().logEvent('Login Success');
            addUserSession(email, token);
            setState(true);
            setTextMessage('Login Success');
          })
          .catch(() => {
            setState(true);
            setTextMessage('La contraseña o el usuario son incorrectos');
            firebase.analytics().logEvent('User or password is incorrect');
          });
      }
    }
  };
  const routeChange = () => {
    window.location.href = 'https://bx.expert/';
  };

  const resetRecaptcha = () => {
    recaptchaInstance.reset();
  };

  const captchaLoaded = () => {
    setLimitRetry(0);
    resetRecaptcha();
  };

  const recaptcha = (props) => {
    if (props) {
      recaptchaInstance = props;
    }
  };

  return (
    <div className={'home-bx'}>

      <Dialog open preventOutsideDismiss>
        <div className={'button-close-login'}>
          <Fab
            className={'button-close-login-fab'}
            icon={'keyboard-backspace'}
            label={'Volver'}
            onClick={routeChange}
          />
        </div>
        <DialogContent style={{ width: '90vw', maxWidth: '480px' }}>
          <div className={'container-login'}>
            {ready
              ? <Grid style={{ margin: '0px' }}>
                <GridRow>
                  <GridCell desktop={12} tablet={12} phone={12}>
                    <div className={'login-group'}>
                      <img alt={'BX login'} src={BXlogin} className={'img-bxlogo'} />
                      <br /><br />
                      <div className={'selector-login'}>
                        <SwitchSelector
                          onChange={onChange}
                          options={options}
                          initialSelectedIndex={initialSelectedIndex}
                          selectedBackgroundColor={'#FFAE39'}
                          backgroundColor={'#F0F0F0'}
                          fontColor={'#000'}
                          selectedFontColor={'#000000'}
                        />
                      </div>

                    </div>
                    <br /><br />
                    {optionSelected === 'iniciar'
                      ? <> <TextField
                          label={'Correo'}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          invalid={emailError.length > 0}
                          helpText={{ validationMsg: true, children: emailError }}
                          style={{ backgroundColor: '#fff' }}
                      />

                        <TextField
                          type={'password'}
                          label={'Contraseña'}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          invalid={passwordError.length > 0}
                          helpText={{ validationMsg: true, children: passwordError }}
                          style={{ backgroundColor: '#fff' }}
                        />

                        <div className={'login-group'}>
                          <ButtonMdc
                            label={'Ingresar'}
                            raised
                            onClick={login}
                            className={'button-medium'}
                          />
                        </div>
                        <br />
                        <a className={'recoverypass-a'} href={'/recovery'}>¿Olvidaste tu contraseña?</a>
                        <Snackbar
                          open={state}
                          onClose={() => setState(false)}
                          message={textMessage}
                        />
                      </>
                      : <> <div className={'register'}><Register /></div></>}
                    <div className={`${(limitRetry) >= 3 ? 'show' : 'hide'} captcha`}>
                      <Recaptcha
                        ref={(e) => recaptcha(e)}
                        render={'explicit'}
                        verifyCallback={captchaLoaded}
                        sitekey={process.env.REACT_APP_SITEKEY}
                      />
                    </div>
                  </GridCell>
                  <GridCell desktop={12} tablet={12} phone={12}>
                    <div className={`${(limitRetry) < 3 ? 'show' : 'hide'}`} />
                  </GridCell>
                </GridRow>
              </Grid>
              : <Loader />}
          </div>
        </DialogContent>
      </Dialog>
    </div>

  );
};

export default LoginForm;
