import { api, escalateError, getResponseData } from './index';

export default class CoursesApi {
  static updateCourse(id, statusBx, price, releaseDate) {
    return api.put('/courses', {
      id,
      price,
      visible: statusBx,
      releaseDate,
    })
      .then(getResponseData)
      .catch(escalateError);
  }

  static updateCategory(id, name, statusBx, price, prices, releaseDate) {
    return api.put('/courses/categories', {
      id: `${id}`,
      name,
      price,
      visible: statusBx,
      releaseDate,
      prices,
    })
      .then(getResponseData)
      .catch(escalateError);
  }

  static getCourse(id) {
    return api.get(`/courses/${id}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static getCategory(id) {
    return api.get(`/courses/categories/${id}`)
      .then(getResponseData)
      .catch(escalateError);
  }

  static getEpaycoPublicKey() {
    return api.get('/epaycoPublicKey')
      .then(getResponseData)
      .catch(escalateError);
  }

  static getMyCourses(userId) {
    return api.post('/myCourses', { userId })
      .then(getResponseData)
      .catch(escalateError);
  }
}
