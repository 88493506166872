import { api, escalateError } from './index';

export default class DataProfile {
  static async getUserDataProfile(email) {
    try {
      return await api.get(`/users/user?email=${email}&purpose=user`);
    } catch (e) {
      escalateError(e);
      return false;
    }
  }
}
