/* eslint-disable react/jsx-closing-tag-location */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TopAppBar, TopAppBarActionItem, TopAppBarFixedAdjust, TopAppBarRow, TopAppBarSection,
} from '@rmwc/top-app-bar';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Typography } from '@rmwc/typography';
import './Toolbar.css';
import { Menu, MenuItem, MenuSurfaceAnchor } from '@rmwc/menu';
import firebase from '../../firebase';
import BXLogo from '../../res/images/bx-logo.svg';
import MDIcon from './MDIcon';
import LoginRoutesContext from '../../contexts/login-routes-context';

const Toolbar = ({ useCustomTheme = true }) => {
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = useState(false);
  const [urlImage, setUrlImage] = useState();
  const [isEnterprise, setIsEnterprise] = useState(false);
  const [page, setPage] = useState('');
  const { isEnterpriseContext } = useContext(LoginRoutesContext);
  const history = useHistory();
  useEffect(() => {
    setIsEnterprise(isEnterpriseContext);
  }, [isEnterpriseContext]);

  const renderToolbar = (content) => {
    if (useCustomTheme) {
      return (
        <div id={'render-toolbar'}>
          <TopAppBar style={{ background: '#fff' }}>
            {content}
          </TopAppBar>
        </div>
      );
    }
    return (<TopAppBar>{content}</TopAppBar>);
  };

  useEffect(() => {
    firebase.auth()
      .onAuthStateChanged(async (user) => {
        if (user) {
          // User is signed in.
          localStorage.setItem('photoURL', user.photoURL);
          localStorage.setItem('mail', user.email);
          setUrlImage(localStorage.getItem('photoURL'));
          setShow(true);
        }
        // persistence session
        if (!localStorage.getItem('mail')) {
          setShow(false);
          const route = window.location.href.split('/');
          const direction = route[3].toLocaleLowerCase()
            .trim();
          if (direction !== 'login' && direction !== 'new-user') {
            history.push('/');
          }
        } else {
          await setUrlImage(localStorage.getItem('photoURL'));
          setShow(true);
        }
        return user;
      });
  }, []);

  const Redirection = (pages) => {
    const { page } = pages;
    if (page && page !== '' && localStorage.getItem('mail')) {
      return <Redirect to={`/${page}`} />;
    }
    return <></>;
  };

  const signOut = () => {
    try {
      setPage();
      localStorage.clear();
      firebase.auth().signOut();
    } catch (e) { // an error
    }
  };

  const Profile = () => {
    if (urlImage !== 'null') {
      return (<img src={urlImage} className={'profile'} alt={'P'} />);
    }
    return (<MDIcon icon={'account'} style={{ height: '100%' }} />);
  };

  return (
    <>
      <div
        onKeyDown={() => { }}
        onClick={() => {
          setTimeout(() => {
            setPage();
          }, 100);
        }}
      >
        {renderToolbar(
          <TopAppBarRow>
            <Redirection page={page} />
            <TopAppBarSection alignStart>
              <Link to={'/home'} className={'toolbar-logo'}>
                <img alt={'BX Logo'} src={BXLogo} />
              </Link>
            </TopAppBarSection>
            <TopAppBarSection alignEnd className={'toolbar-options'}>
              <div className={'nav-container'}>
                <div className={'nav-options'}>
                  <a href={process.env.REACT_APP_EMPLEOS_LOGIN} target={'_blank'} rel={'noreferrer'}><Typography>Bolsa laboral</Typography></a>
                  <a href={process.env.REACT_APP_MOODLE_LOGIN} target={'_blank'} rel={'noreferrer'}><Typography>Cursos</Typography></a>
                  <a href rel={'noreferrer'}>
                    <Typography onClick={() => history.push('/hometest')} style={{ cursor: 'pointer' }}>Pruebas DISC</Typography>
                  </a>
                  {!isEnterprise
                    ? <a href rel={'noreferrer'}>
                      <Typography onClick={() => history.push('/home/mis-cursos')} style={{ cursor: 'pointer' }}>Mi Espacio</Typography>
                    </a>
                    : <Typography>Planes</Typography>}
                </div>
                <div className={'toolbar-buttons'}>
                  {show
                    ? <>
                      <MenuSurfaceAnchor>
                        <Menu open={open} onClose={() => setOpen(false)}>
                          <MenuItem onClick={() => signOut()}>
                            <MDIcon icon={'logout'} className={'icon-bar'} />Salir
                          </MenuItem>
                        </Menu>
                        <TopAppBarActionItem
                          icon={''}
                          className={'profile-tool'}
                          onClick={() => setOpen(!open)}
                        >
                          <Profile />
                        </TopAppBarActionItem>
                      </MenuSurfaceAnchor>
                    </>
                    : <Link to={'/'} />}
                  <Link to={'/'}>
                    <Typography className={'toobar-singup'} onClick={() => { signOut(); }}>Salir</Typography>
                  </Link>
                </div>
              </div>
            </TopAppBarSection>
          </TopAppBarRow>,
        )}
        <TopAppBarFixedAdjust style={{ paddingTop: '0px' }} />
      </div>
    </>
  );
};

Toolbar.propTypes = {
  useCustomTheme: PropTypes.bool,
};

export default Toolbar;
