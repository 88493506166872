import React from 'react';
import CardCourse from './CardCourse';
import front from '../../res/images/backend.png';
import back from '../../res/images/front.png';
import data from '../../res/images/data.png';

const Courses = () => {
  // Estos campos hay que parametrizarlos desde la base de datos, por tiempo se deja así
  const descriptionFrontend = 'Aprende los lenguajes de programación y desarrolla las competencias esenciales más demandadas del mercado laboral.';
  const descriptionBackend = 'Aprende los elementos esenciales para el correcto funcionamiento de una aplicación y especialízate en el desarrollo técnico y funcional de un sitio web.';
  const descriptionDataScience = 'Aprende, explora, diseña y gestiona productos basados en análisis de datos. Te formaremos desde cero en el uso del lenguaje Python para una mejor comprensión de datos.';
  return (
    <>
      <section className={'courses-section'}>
        <h2>Cursos</h2>
        <div className={'__courses-section'}>
          <div className={'courses-section-grid'}>
            {/* <CardCourse color={'honey'} imgSrc={compu} mainText={'Desarrollado FullStack'} />
          <CardNewCourse color={'blue'} imgSrc={board} mainText={'Data Science'} /> */}
            <CardCourse productId={process.env.REACT_APP_FRONTEND} color={'salmon'} imgSrc={front} mainText={'Front End'} duration={16} description={descriptionFrontend} />
            <CardCourse productId={process.env.REACT_APP_BACKEND} color={'blue'} imgSrc={back} mainText={'Back End'} duration={16} description={descriptionBackend} />
            <CardCourse productId={process.env.REACT_APP_DATASCIENCE} color={'honey'} imgSrc={data} mainText={'Ciencia de datos'} duration={20} description={descriptionDataScience} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Courses;
