import React from 'react';
import { Typography } from '@rmwc/typography';
import { Carousel } from 'react-responsive-carousel';
import partners from '../../res/images/partners.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './partners.css';

const Partners = () => (

  <div className={'partners-container'}>
    <div className={'text-partners'}>
      <Typography>Conoce algunos de nuestros partners</Typography>
    </div>
    <div className={'carousel-container'}>
      <Carousel
        autoPlay
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        infiniteLoop
        transitionTime={500}
      >
        <div>
          <img alt={''} src={partners} />
        </div>
        <div>
          <img alt={''} src={partners} />
        </div>
        <div>
          <img alt={''} src={partners} />
        </div>
      </Carousel>
    </div>
  </div>

);

export default Partners;
