import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const SaveDiscAnswersContext = createContext();

const SaveDiscAnswersContextProvider = ({ children }) => {
  const [characteristics, setCharacteristics] = useState([]);
  const [preguntasMostradas, setPreguntasMostradas] = useState(0);
  const [compound, setCompound] = useState(1);

  useEffect(() => {
    const storedData = localStorage.getItem('characteristics');
    const index = localStorage.getItem('index');
    const compoundNumber = localStorage.getItem('compound');

    if (storedData) {
      setCharacteristics(JSON.parse(storedData));
      setPreguntasMostradas(JSON.parse(index));
      setCompound(JSON.parse(compoundNumber));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('characteristics', JSON.stringify(characteristics));
    localStorage.setItem('index', preguntasMostradas);
    localStorage.setItem('compound', compound);
  }, [characteristics, preguntasMostradas]);

  useEffect(() => {
    if (characteristics.length === 24) {
      localStorage.removeItem('characteristics');
      localStorage.removeItem('index');
      localStorage.removeItem('compound');
    }
  }, [characteristics]);

  return (
    <SaveDiscAnswersContext.Provider value={{
      characteristics,
      setCharacteristics,
      preguntasMostradas,
      setPreguntasMostradas,
      compound,
      setCompound,
    }}
    >
      {children}
    </SaveDiscAnswersContext.Provider>
  );
};

SaveDiscAnswersContextProvider.propTypes = {
  children: PropTypes.oneOfType(
    [PropTypes.object, PropTypes.arrayOf(PropTypes.element)],
  ).isRequired,
};

export default SaveDiscAnswersContextProvider;
