import axios from 'axios';
import { api, escalateError } from './index';

export default class OrdersApi {
  static async initNewOrder(body) {
    try {
      const response = await api.post('/payments', body);
      const { invoice, totalPrice } = response.data;
      return {
        invoice,
        price: totalPrice,
      };
    } catch (error) {
      escalateError(error);
      return false;
    }
  }

  static async getTransaction(refPayco) {
    try {
      const base = 'https://secure.epayco.co/validation/v1/reference';
      const response = await axios.get(`${base}/${refPayco}`);
      const { data } = response.data;
      return data;
    } catch (error) {
      escalateError(error);
      return false;
    }
  }

  static async validationPayment(userId, productId) {
    try {
      const body = { userId, productId };
      const response = await api.post('/payments/validationPayment', body);
      return response.data;
    } catch (error) {
      escalateError(error);
      return false;
    }
  }

  static async validationPaymentInProgress(userId, productId) {
    try {
      const body = { userId, productId };
      const response = await api.post('/payments/validationPaymentInProgress', body);
      return response.data;
    } catch (error) {
      escalateError(error);
      return false;
    }
  }
}
