import React from 'react';
import Toolbar from '../mdc/Toolbar';
import Partners from '../partners/partners';
import Programs from '../programs/programs';
import Slider from '../slider/Slider';
import Courses from '../courses/Courses';
import mainSlide from '../../res/images/pc.png';
import Events from '../calendarevents/Events';
import Footer from '../Footer/Footer';
import Graduates from '../graduates/graduates';
import Newsletter from '../newsletter/newsletter';
import HamburguerMenu from '../hamburguerMenu/hamburguerMenu';

const HomePerson = () => {
  const firstText = 'Juntos seremos el panal para';
  const secondText = 'desarrollar tus habilidades';
  const itemsMenu = [{
    href: process.env.REACT_APP_EMPLEOS_LOGIN,
    name: 'Bolsa laboral',
  }, {
    href: process.env.REACT_APP_MOODLE_LOGIN,
    name: 'Cursos',
  },
  {
    href: '/home/mis-cursos',
    name: 'Mi espacio',
  },
  ];

  return (
    <>
      <Toolbar />
      <HamburguerMenu itemsMenu={itemsMenu} />
      <Slider firstText={firstText} secondText={secondText} mainSlide={mainSlide} buttonText={'¡Busca un empleo!'} bottom />
      <Partners />
      <Courses />
      <Programs />
      <Events />
      <Graduates />
      <Newsletter />
      <Footer />
    </>
  );
};

export default HomePerson;
