import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import help from '../../res/images/help.png';

const Spot = ({
  type, text, spot, handleDrop, subTitle, emoji,
  textQuestion,
}) => {
  const [view, setView] = useState(true);
  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: type,
    drop: (item) => {
      handleDrop(spot, item);
      // here is where we do the update
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  let backgroundColor = '#434343';
  if (canDrop) backgroundColor = '#737373';
  if (isOver) backgroundColor = '#E79200';
  return (
    <div className={'option-drop'}>
      {(view)
        ? (
          <>
            <img alt={'alt'} src={emoji} />
            <p>{subTitle}</p>
            <div className={'spot'} ref={dropRef} style={{ backgroundColor }}>{text}</div>
          </>)
        : (
          <div className={'option-description'}>
            <p>
              {textQuestion}
            </p>
          </div>
        )}

      <button type={'button'} onClick={() => setView(!view)}>
        <img alt={'alt'} src={help} />
      </button>
    </div>
  );
};
export default Spot;
