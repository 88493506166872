/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Dialog, DialogTitle } from '@rmwc/dialog';
import ButtonMdc from '../../mdc/button/ButtonMdc';
import './modalRouts.css';

const ModalDetails = ({ letter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const letterRout = [
    { letter: 'D', info: ['Gestión del tiempo', 'Delegación de tareas', 'Trabajo en equipo', 'Empatía'], url: 'https://educacion.bx.expert/course/view.php?id=156' },
    { letter: 'I', info: ['Planificación y organización', 'Atención al detalle', 'Gestión del tiempo', 'Toma de decisiones'], url: 'https://educacion.bx.expert/course/view.php?id=149' },
    { letter: 'S', info: ['Comunicación efectiva', 'Toma de decisiones', 'Liderazgo', 'Gestión de conflictos'], url: 'https://educacion.bx.expert/course/view.php?id=157' },
    { letter: 'C', info: ['Comunicación efectiva', 'Trabajo en equipo', 'Toma de decisiones', 'Gestión del tiempo'], url: 'https://educacion.bx.expert/course/view.php?id=158' },
  ];
  return (
    <>
      <ButtonMdc
        style={{ background: '#ffa80a', borderRadius: '100px', color: 'white' }}
        onClick={() => setIsOpen(true)}
      >
        Curso sugerido <span className={'mdi mdi-cursor-default-click-outline'} style={{ fontSize: '18pt' }} />
      </ButtonMdc>
      <Dialog
        open={isOpen}
        className={'cardDialog'}
        onClose={() => setIsOpen(false)}
        preventOutsideDismiss
      >
        <div className={'conten-Details'}>
          <span className={'close-x mdi mdi-close'} onClick={() => setIsOpen(false)} />
          <DialogTitle className={'Dialog-title '}>Ruta de aprendizaje para la letra {letter}</DialogTitle>
          <p className={'dialog-conten-text'}><b>El Enfoque de los cursos es:</b></p>
          <p className={'dialog-conten-text'}>
            {letter ? <ul>{(letterRout.find((e) => e.letter === letter).info).map((e) => <li><b>•</b> {e}</li>)}</ul> : ''}
          </p>
          <div className={'conten-button'}>
            <ButtonMdc
              className={'button-res'}
              label={'Ir a la ruta'}
              onClick={() => {
                window.open(letterRout.find((e) => e.letter === letter).url);
              }}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ModalDetails;
