import React from 'react';
import { Typography } from '@rmwc/typography';
import PersonsTalking from '../../res/images/PersonsTalking.png';
import Line from '../../res/images/Line.png';
import './searchCandidates.css';

const SearchCandidates = () => (
  <div className={'container-candidates'}>
    <div className={'container-image-candidates'}>
      <img alt={'bx-person'} src={PersonsTalking} />
    </div>
    <div className={'container-background-none-text'}>
      <h2>Candidatos compatibles con tú búsqueda</h2>
      <img alt={'bx-line'} src={Line} />
      <a href={process.env.REACT_APP_EMPLEOS_LOGIN} target={'_blank'} className={'link-style'} rel={'noreferrer'}>
        <Typography className={'button-see-programs-candidates'}> Ver
        </Typography>
      </a>

    </div>

  </div>

);

export default SearchCandidates;
