import React from 'react';
import PersonOne from '../../res/images/nutresa.png';
import PersonTwo from '../../res/images/bogota.png';
import PersonThree from '../../res/images/tecnologia.png';
import './graduates.css';

const Enterprises = () => (
  <div className={'container'}>
    <h2 className={'title-section-graduates'}>Empresas que confían en nosotros</h2>
    <div className={'gallery'}>
      <article className={'card'}>
        <div className={'card__img'}>
          <img alt={'person'} src={PersonOne} />
        </div>
        <p className={'description-text'}>
          “Una gran opción para capacitar a nuestros colaboradores y
          potencializar sus conocimientos y adquirir nuevos”
        </p>
        <h4 className={'card__title'}>
          Omar Salamanca
        </h4>
        <p className={'text-title'}>
          Administrador de empresas
        </p>
      </article>
      <article className={'card'}>
        <div className={'card__img'}>
          <img alt={'person'} src={PersonTwo} />
        </div>

        <p className={'description-text'}>
          “Los ciudadanos de Bogotá y Colombia aprovecharán la
          plataforma buscando reducir los niveles de desempleo”
        </p>
        <h4 className={'card__title'}>
          Samuel Perez
        </h4>
        <p className={'text-title'}>
          Gerente de proyectos
        </p>
      </article>
      <article className={'card'}>
        <div className={'card__img'}>
          <img alt={'person'} src={PersonThree} />
        </div>
        <p className={'description-text'}>
          “La plataforma ofrece muy buenos programas de aprendizaje que permiten mejorar
          las habilidades de nuestro personal”
        </p>
        <h4 className={'card__title'}>
          Camilo Pedraza
        </h4>
        <p className={'text-title'}>
          Ingeniero Industrial
        </p>
      </article>
    </div>
  </div>

);

export default Enterprises;
