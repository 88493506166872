import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  VictoryChart,
  VictoryPolarAxis,
  VictoryArea,
  VictoryLine,
  VictoryScatter,
} from 'victory';

const dataLabel = [
  { category: 'S', value: 10, fillColor: '#FFB126' },
  { category: 'I', value: 10, fillColor: '#E32256' },
  { category: 'D', value: 10, fillColor: '#1496F9' },
  { category: 'C', value: 10, fillColor: '#35CCDB' },
];

const RadarChart = ({
  d, i, c, s,
}) => {
  const dataRest = [
    { category: 'S', value: s },
    { category: 'I', value: i },
    { category: 'D', value: d },
    { category: 'C', value: c },
  ];
  return (
    <>
      <VictoryChart polar width={300} height={300}>
        <VictoryPolarAxis labelPlacement={'vertical'} style={{ axis: { stroke: 'none' }, tickLabels: { fill: '#5C5C5C' } }} />
        <VictoryLine
          style={{ data: { stroke: 'black', strokeWidth: 0.5, strokeDasharray: '2' } }}
          data={[
            { x: 'C', y: 0 },
            { x: 'C', y: 12 },
            { x: 'I', y: 0 },
            { x: 'I', y: 12 },
            { x: 'D', y: 0 },
            { x: 'D', y: 12 },
            { x: 'S', y: 0 },
            { x: 'S', y: 12 },
          ]}
        />
        <VictoryScatter
          data={dataLabel}
          x={'category'}
          y={'value'}
          size={4}
          symbol={'circle'}
          style={{
            data: {
              fill: ({ datum }) => (datum.fillColor),
            },
          }}
        />
        <VictoryArea
          data={dataLabel}
          x={'category'}
          y={'value'}
          style={{
            data: {
              fill: 'black', fillOpacity: 0, strokeWidth: 0.5, strokeDasharray: '2',
            },
          }}
        />
        <VictoryArea
          data={dataLabel.map((e) => e.value - 3.333)}
          x={'category'}
          y={'value'}
          style={{
            data: {
              fill: 'black', fillOpacity: 0, strokeWidth: 0.5, strokeDasharray: '2',
            },
          }}
        />
        <VictoryArea
          data={dataLabel.map((e) => e.value - 6.666)}
          x={'category'}
          y={'value'}
          style={{
            data: {
              fill: 'black', fillOpacity: 0, strokeWidth: 0.5, strokeDasharray: '2',
            },
          }}
        />
        <VictoryArea
          data={dataRest}
          x={'category'}
          y={'value'}
          style={{
            data: {
              fill: 'url(#gradient)', stroke: 'url(#gradient)', fillOpacity: 0.7, strokeWidth: 0.5,
            },
          }}
        />
        <defs>
          <linearGradient id={'gradient'} x1={'40%'} y1={'100%'} x2={'40%'} y2={'35%'}>
            <stop offset={'25%'} stopColor={'#FFB126'} />
            <stop offset={'50%'} stopColor={'#35CCDB'} />
            <stop offset={'75%'} stopColor={'#1496F9'} />
            <stop offset={'100%'} stopColor={'#E32256'} />
          </linearGradient>
        </defs>
      </VictoryChart>
    </>
  );
};

export default RadarChart;
