import React from 'react';
import PropTypes from 'prop-types';
import { RMWCProvider } from '@rmwc/provider';
import { ThemeProvider } from '@rmwc/theme';
import DrawerProvider from './drawer/DrawerProvider';
import '@rmwc/icon/styles';
import '@rmwc/theme/styles';
import '@rmwc/button/styles';
import '@rmwc/menu/styles';
import '@rmwc/top-app-bar/styles';
import '@rmwc/snackbar/styles';
import '@rmwc/drawer/styles';
import '@rmwc/list/styles';
import '@rmwc/icon-button/styles';
import '@rmwc/textfield/styles';
import '@rmwc/fab/styles';
import '@rmwc/data-table/styles';
import '@mdi/font/css/materialdesignicons.min.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@rmwc/formfield/styles';
import '@rmwc/checkbox/styles';
import '@rmwc/grid/styles';
import '@rmwc/circular-progress/styles';
import '@rmwc/switch/styles';
import '@rmwc/dialog/styles';
import '@rmwc/elevation/styles';
import '@rmwc/typography/styles';
import '@rmwc/card/styles';
import '@rmwc/tabs/styles';
import './mdc.css';

const MaterialWrapper = ({ children }) => (
  <RMWCProvider
    icon={{
      strategy: 'className',
      basename: 'mdi',
      prefix: 'mdi-',
    }}
  >
    <ThemeProvider options={{
      primary: '#D1D1D1',
      primaryBg: '#1977ff',
      secondary: '#6ec901',
      secondaryBg: '#6ec901',
      error: '#d43551',
      background: '#fafafa',
      onSecondary: '#0a3066',
    }}
    >
      <DrawerProvider>
        {children}
      </DrawerProvider>
    </ThemeProvider>
  </RMWCProvider>
);

MaterialWrapper.propTypes = {
  children: PropTypes.oneOfType(
    [PropTypes.object, PropTypes.arrayOf(PropTypes.element)],
  ).isRequired,
};

export default MaterialWrapper;
