/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './Footer.css';
import facebook from '../../res/images/facebook.png';
import instagram from '../../res/images/instagram.png';
// import linkedin from '../../res/images/linkedin.png';
// import twitter from '../../res/images/twitter.png';
import bxwhite from '../../res/images/bxwhite.png';

const Footer = () => (
  <section className={'footer'}>
    <div className={'first-footer'}>
      <div><img alt={'bx'} src={bxwhite} /></div>
      <div>
        <h4>Nosotros</h4>
        <a href={'#'}>Quienes somos</a>
        <a href={'#'}>Contáctanos</a>
      </div>
      <div>
        <h4>Políticas BDG institute</h4>
        <a href={'#'}>Política de privacidad y cookies</a>
        <a href={'#'}>Términos y condiciones</a>
        <a href={'#'}>Tratamiento de datos</a>
      </div>
      <div>
        <h4>Servicios al estudiante</h4>
        <a href={'#'}>Blog</a>
        <a href={'#'}>Ayuda y soporte</a>
        <a href={'#'}>Términos y condiciones</a>
      </div>
    </div>
    <div className={'second-footer'}>
      <p>Síguenos</p>
      <div className={'redes-sociales'}>
        <a target={'_blank'} href={'https://www.facebook.com/profile.php?id=100087658922280'} rel={'noreferrer'}><img alt={'facebook'} src={facebook} /></a>
        <a target={'_blank'} href={'https://instagram.com/bxexpert?igshid=NTdlMDg3MTY='} rel={'noreferrer'}><img alt={'instagram'} src={instagram} /></a>
        {/* <a href={'#'}><img alt={'linkedin'} src={linkedin} /></a>
        <a href={'#'}><img alt={'twitter'} src={twitter} /></a> */}
      </div>
    </div>
    <div className={'third-footer'}>Copyright © 2021 BDG Institute. All rights reserved</div>
  </section>
);

export default Footer;
