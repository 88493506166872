/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import './homeTest.css';
import Toolbar from '../mdc/Toolbar';
import Footer from '../Footer/Footer';
import Newsletter from '../newsletter/newsletter';
import HamburguerMenu from '../hamburguerMenu/hamburguerMenu';
import imagen from '../../res/images/homeDisc.png';
import ModalWelcome from './modalWelcome/ModalWelcome';
import ModalInfoDisc from './modalInfoDisc/ModalInfoDisc';

const HomeTest = () => {
  const itemsMenu = [{
    href: process.env.REACT_APP_EMPLEOS_LOGIN,
    name: 'Bolsa laboral',
  }, {
    href: process.env.REACT_APP_MOODLE_LOGIN,
    name: 'Cursos',
  },
  {
    href: '/home/mis-cursos',
    name: 'Mi espacio',
  },
  ];

  return (
    <>
      <Toolbar />
      <HamburguerMenu itemsMenu={itemsMenu} />
      <div className={'split-screen'}>
        <div className={'left'}>
          <div className={'text'}>
            <br /><br />
            Haz tu prueba de
          </div>
          <div className={'text-y'}>
            personalidad
          </div>
          <p className={'container-text'}>
            <strong>Tomamos la metodología DISC y la potenciamos,</strong> sumando <br />
            componentes esenciales como la medición de competencias.<br /><br />
            Para iniciar, haz click sobre el botón "Empieza aquí", <br />
            si deseas obtener más información acerca de las pruebas <br />
            DISC haz click sobre el botón "Información"
          </p>
          <div className={'container-button'}>
            <ModalWelcome /> <br /> <br />
            <ModalInfoDisc />
          </div>
        </div>
        <div className={'right'}>
          <br /><br /><br />
          <img src={imagen} alt={'Imagen home'} />
        </div>

      </div>
      <Newsletter />
      <Footer />
    </>
  );
};

export default HomeTest;
