import React from 'react';
import './Admin.css';
import { useHistory } from 'react-router-dom';

const LateralMenu = () => {
  const history = useHistory();
  const itemsMenu = [{ name: 'Cursos y rutas', route: '/bx-admin/cursosyrutas' }, { name: 'Comercio', route: '/bx-admin/comercio' }, { name: 'Usuarios', route: '/bx-admin/users' }];
  const logOut = () => {
    localStorage.removeItem('mail');
    localStorage.removeItem('isEnterprise');
    localStorage.removeItem('photoURL');
    localStorage.removeItem('token');
    localStorage.removeItem('userID');
    history.push('/bx-admin');
  };

  return (
    <nav style={{
      backgroundColor: '#222c31',
      height: '100vh',
      minWidth: '200px',
      margin: 0,
      position: 'sticky',
      top: '0px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'space-between',
      justifyContent: 'space-between',
    }}
    >
      <ul className={'admin-menu'}>
        {itemsMenu.map((e) => <li key={e.name}><a href={e.route}>{e.name}</a></li>)}
      </ul>
      <ul className={'admin-menu'}>
        <li><button className={'log-out'} type={'button'} onClick={logOut}>Cerrar sesión</button></li>
      </ul>
    </nav>
  );
};

export default LateralMenu;
