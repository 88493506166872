import React, { useState } from 'react';
import { Snackbar } from '@rmwc/snackbar';
import ButtonMdc from '../mdc/button/ButtonMdc';
import './newsletter.css';
import NewsletterAPi from '../../api/newsletterApi';

const Newsletter = () => {
  const [text, setText] = useState('');
  const [, setMessage] = useState('');
  const [holder, setHolder] = useState('Escribe tu correo electrónico');
  const [textMessage, setTextMessage] = useState('');
  // eslint-disable-next-line no-useless-escape
  const regex = /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;
  const [state, setState] = useState(false);
  const validateFields = () => {
    if (text.length <= 0) {
      setHolder('Escriba un correo electronico');
      return false;
    }
    if (!regex.test(text)) {
      setText('');
      setHolder('ingrese un correo electronico valido');
      setTextMessage('el correo ingresado no es valido');
      setState(true);
      return false;
    }
    setHolder('');

    return true;
  };
  const sendData = () => {
    if (validateFields()) {
      NewsletterAPi.createItemInDb(text)
        .then(() => {
          setTextMessage('su correo ha sido registrado en nuestro newsletter');
          setState(true);
          setHolder('Escribe tu correo electrónico');
          setText('');
        })
        .catch((err) => {
          setMessage(`Please retry ${(err.message || 'Unexpected error')}`);
          setTextMessage('su correo ha sido registrado en nuestro newsletter 33333');
          setState(true);
          setHolder('Escribe tu correo electrónico');
          setText('');
        });
    }
  };
  return (
    <>
      <div className={'container-newsletter'}>
        <div action={''} className={'newsletter-form'}>
          <div className={'card-suscribe'}>
            <h2>Suscríbete a nuestro newsletter
            </h2>
            <p>y obtén ofertas y actualizaciones</p>
          </div>
          <div className={'container-suscribe'}>
            <input
              className={'emailbox'}
              type={'email'}
              name={''}
              value={text}
              placeholder={holder}
              onChange={(e) => setText(e.target.value)}
            />
            <ButtonMdc
              className={'button-newsletter'}
              onClick={() => sendData()}
              label={'Suscribirme'}
              raised
              icon={'arrow-right'}
            />
            <Snackbar
              open={state}
              onClose={() => setState(false)}
              message={textMessage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Newsletter;
