/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react';
import { DndProvider } from 'react-dnd';
import { Snackbar } from '@rmwc/snackbar';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { SaveDiscAnswersContext } from '../../contexts/saveDiscAnswers-context';
import DiscApi from '../../api/DiscApi';
import Spot from './Spot';
import CardDrag from './CardDrag';
import './CardDrag.css';
import ModalDisc from './modalDisc/ModalDisc';
import Stepper from '../stepper/stepper';
import Toolbar from '../mdc/Toolbar';
import Footer from '../Footer/Footer';
import emoji1 from '../../res/images/emoji_1.png';
import emoji2 from '../../res/images/emoji_2.png';
import emoji3 from '../../res/images/emoji_3.png';
import emoji4 from '../../res/images/emoji_4.png';
import ModalTutorial from './modalTutorial/ModalTutorial';
import ModalDaysTest from './modalDaysTest/ModalDaysTest';
import './DashboardDisc.css';

const DashboardDisc = () => {
  const [answerOption, setAnswerOption] = useState({
    alto: '', medioAlto: '', medio: '', bajo: '',
  });
  const {
    characteristics, setCharacteristics, preguntasMostradas, setPreguntasMostradas,
    compound, setCompound,
  } = useContext(SaveDiscAnswersContext);
  const [leadId, setLeadId] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const options = ['alto', 'medioAlto', 'medio', 'bajo'];
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [state, setState] = useState(false);
  const [textMessage, setTextMessage] = useState('');
  const [alerDialog, setalerDialog] = useState(false);
  const [days, setDays] = useState(0);
  const [openDays, setOpenDays] = useState(false);
  const [openWelcome, setOpenWelcome] = useState(false);

  const replaceItem = (index, newValue) => {
    const newArray = characteristics.slice(); // crea una copia del array actual
    newArray[index] = newValue; // reemplaza el valor en la posición especificada
    setCharacteristics(newArray); // actualiza el estado del componente
  };
  const handleDrop = (spot, item) => {
    setAnswerOption({ ...answerOption, [spot]: item.text });
    if (replaceItem) {
      replaceItem(item.text);
    }
  };
  const sendApiAnswerDisc = async (characteristics) => {
    try {
      const answers = characteristics.reduce((previus, current) => [...previus, current[0], current[1], current[2], current[3]], []);
      const data = {
        answers, leadId, email: localStorage.getItem('mail'),
      };
      // eslint-disable-next-line no-unused-vars
      const response = await DiscApi.postAnswerDisc(data);
      if (localStorage !== undefined) {
        const email = localStorage.getItem('mail');
        const user = await DiscApi.putDiscUser(email, leadId);
        if (user) {
          setState(true);
          setTextMessage('Los datos han sido enviados correctamente');
        }
      }

      // aqui hay que hacer una api para traer el usuario por medio del userId o el email y modificar la data
    } catch (err) {
      setError(`Hubo un error al enviar las preguntas de la API: ${err}`);
    }
  };

  const handleNextClick = () => {
    if (preguntasMostradas < 24) {
      const hasAllAnswerOptions = Object.values(answerOption).every((option) => option !== '');
      const uniqueAnswerOptions = new Set(Object.values(answerOption));
      const hasUniqueAnswerOptions = uniqueAnswerOptions.size === options.length;
      if (hasAllAnswerOptions && hasUniqueAnswerOptions) {
        const newAnswers = Object.keys(answerOption).map((option, index) => ({
          compound_number: `${compound}.${index + 1}`,
          title: questions[preguntasMostradas].characteristics[index].title,
          response: answerOption[option],
          letter: questions[preguntasMostradas].characteristics[index].letter,
        }));
        setAnswers([...answers, ...newAnswers]);
        const updatedCharacteristics = [...characteristics];
        updatedCharacteristics[preguntasMostradas] = { ...newAnswers };
        setCharacteristics(updatedCharacteristics);

        if ((preguntasMostradas + 1) < 24) {
          setPreguntasMostradas(preguntasMostradas + 1);
        }
        setAnswerOption({
          alto: '', medioAlto: '', medio: '', bajo: '',
        });
        setCompound(compound + 1);
      } else {
        setIsNextDisabled(false);
        setTextMessage('Por favor seleccione una respuesta única para cada opción');
        setState(true);
      }
      setIsNextDisabled(true);
    } else {
      setTextMessage('Por favor seleccione una respuesta para cada opción');
      setState(true);
      setIsNextDisabled(false);
      setError('Por favor, seleccione una respuesta para cada opción.');
    }
  };

  const handleBackClick = () => {
    if (preguntasMostradas > 0) {
      const updatedCharacteristics = [...characteristics];
      updatedCharacteristics[preguntasMostradas - 1] = { ...answerOption };
      setCharacteristics(updatedCharacteristics);
      setPreguntasMostradas(preguntasMostradas - 1);
      setCompound(compound - 1);
    }
  };
  const getDays = () => {
    DiscApi.getTimeTest(localStorage.getItem('mail'))
      .then((res) => {
        setDays(res.data.daysToNextTest);
        if (res.data.daysToNextTest === undefined || res.data.daysToNextTest === 0) {
          setOpenWelcome(true);
        } else {
          setOpenDays(true);
        }
      })
      .catch((err) => setError(`Hubo un error al obtener dias restantes para DISC test API: ${err}`));
  };
  const getApiQuestionsDisc = () => {
    DiscApi.getQuestionsDisc()
      .then((todos) => {
        const { token } = todos.data;
        setLeadId(token);
        setQuestions(todos.data.questions);
      }).catch((err) => {
        setError(`Hubo un error al obtener las preguntas de la API: ${err}`);
      });
  };
  useEffect(() => {
    if (characteristics.length === 24) {
      sendApiAnswerDisc(characteristics);
      setalerDialog(true);
    }
  }, [characteristics]);

  useEffect(() => {
    getApiQuestionsDisc();
    getDays();
  }, []);

  const currentQuestion = questions[preguntasMostradas]?.characteristics;

  useEffect(() => {
    if (currentQuestion && currentQuestion.every((q) => characteristics.some((c) => c.title === q.title))) {
      const newAnswers = currentQuestion.map((q) => ({
        compound_number: `${compound}.${preguntasMostradas + 1}`,
        title: q.title,
        response: characteristics.find((c) => c.title === q.title).response,
        letter: q.letter,
      }));
      setAnswers([...answers, ...newAnswers]);
      setCharacteristics([]);
      setPreguntasMostradas(preguntasMostradas + 1);
      setCompound(compound + 1);
    }
  }, [characteristics]);
  // Actualizar el estado de "isNextDisabled" en función de si se han seleccionado respuestas para todas las opciones de respuesta
  useEffect(() => {
    const hasAllAnswerOptions = Object.values(answerOption).every((option) => option !== '');
    setIsNextDisabled(!hasAllAnswerOptions);
  }, [answerOption]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Toolbar />
      <div className={'container-disc-test'}>
        <Stepper activeStep={preguntasMostradas} steps={questions} />
        <div className={'_question-disc'}>
          <p id={'text-dragAndDrop'} style={{ marginTop: '-5px', color: '#5C5C5C', fontSize: '11pt' }}>
            *Arrastra y suelta en cada una de las características y pasa por el símbolo de información para desplegar el significado de cada una de ellas
          </p>
          <div className={'question-disc'}>
            <div className={'math-card'}>
              {questions[preguntasMostradas]?.characteristics.map((pregunta, index) => {
                const emojis = [emoji1, emoji2, emoji3, emoji4];
                return (<Spot
                  key={pregunta.question}
                  type={'response'}
                  text={answerOption[options[index]]}
                  handleDrop={handleDrop}
                  spot={options[index]}
                  subTitle={pregunta.title}
                  emoji={emojis[index]}
                  textQuestion={pregunta.question}
                  replaceItem={(newValue) => replaceItem(index, newValue)}
                />);
              })}
            </div>
            <div>
              <div className={'cards operators'}>
                {['Alto', 'Medio Alto', 'Medio', 'Bajo'].map((o) => (
                  <CardDrag key={o} type={'response'} text={o} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={'buttons-container'}>
          <button className={'button-back-disc'} type={'button'} onClick={handleBackClick}>
            Anterior
          </button>
          <button className={`button-next-disc ${!isNextDisabled ? 'active' : ''}`} type={'button'} disabled={isNextDisabled} onClick={handleNextClick}>
            Siguiente
          </button>
          <ModalDisc open={alerDialog} />
          <ModalTutorial open={openWelcome} />
          <ModalDaysTest open={openDays} days={days} />
          <Snackbar
            open={state}
            onClose={() => setState(false)}
            message={textMessage}
          />
        </div>
      </div>
      <Footer />
    </DndProvider>
  );
};

export default DashboardDisc;
