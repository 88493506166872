import React from 'react';
import PersonOne from '../../res/images/Ellipse5.png';
import PersonTwo from '../../res/images/Ellipse6.png';
import PersonThree from '../../res/images/Ellipse7.png';
import './graduates.css';

const Graduates = () => (
  <div className={'container'}>
    <h2 className={'title-section-graduates'}>Nuestros graduados</h2>
    <div className={'gallery'}>
      <article className={'card'}>
        <div className={'card__img'}>
          <img alt={'person'} src={PersonOne} />
        </div>
        <p className={'description-text'}>
          “Los cursos de la plataforma le dieron una vuelta a mi vida,
          descubrí una pasión y desarrolle un talento que no sabía que tenía”
        </p>
        <h4 className={'card__title'}>
          Carlos Vargas
        </h4>
        <p className={'text-title'}>
          Ingeniero Industrial
        </p>
      </article>
      <article className={'card'}>
        <div className={'card__img'}>
          <img alt={'person'} src={PersonTwo} />
        </div>

        <p className={'description-text'}>
          “Excelentes profesores y muy buen programa de aprendizaje,
          las mentorias me ayudaron a mejorar mi calificación en la certificación final”
        </p>
        <h4 className={'card__title'}>
          Gina Sanchéz
        </h4>
        <p className={'text-title'}>
          Ingeniera Ambiental
        </p>
      </article>
      <article className={'card'}>
        <div className={'card__img'}>
          <img alt={'person'} src={PersonThree} />
        </div>
        <p className={'description-text'}>
          “Gracias a BX pude potencializar mi carrera como diseñadora,
          aprendí mucho y mejoré mis habilidades técnicas”
        </p>
        <h4 className={'card__title'}>
          Mariana Vargas
        </h4>
        <p className={'text-title'}>
          Diseñadora gráfica
        </p>
      </article>
    </div>
  </div>

);

export default Graduates;
