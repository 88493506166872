import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
} from '@rmwc/dialog';
import teeneager from '../../res/images/tenegerpayments.png';
import cart from '../../res/images/cart.svg';
import CoursesApi from '../../api/courses';
import OrdersApi from '../../api/orders';
import './Shop.css';

const ProductShop = ({
  productId, open, setOpen, title, description,
}) => {
  const [publicKey, setPublicKey] = useState('');
  const toCurrency = (price) => price.toLocaleString('es-CO', {
    style: 'currency',
    currency: 'COP',
  });
  const handler = window.ePayco.checkout.configure({
    key: publicKey,
    test: false,
  });
  const [priceToPay, setPriceToPay] = useState(0);
  const [paymentData, setPaymentData] = useState({});
  const [paymentType, setpaymentType] = useState('completo');
  const [paymentInProgress, setPaymentInProgress] = useState({ finishPayment: false });
  useEffect(() => {
    const getData = async () => {
      const userId = localStorage.getItem('userID');

      const arePaymentInProgress = await OrdersApi.validationPaymentInProgress(userId, productId);
      const courseData = await CoursesApi.getCategory(productId);
      const { prices } = courseData;
      if (arePaymentInProgress.progressPayment) {
        setPriceToPay(arePaymentInProgress.nextPay);
        setPaymentInProgress(arePaymentInProgress);
      } else {
        setPriceToPay(prices['0'] || 0);
      }
      setPaymentData({
        userId,
        ...courseData,
      });
    };
    const getEpaycoKey = async () => {
      const publicKey = await CoursesApi.getEpaycoPublicKey();
      setPublicKey(publicKey);
    };
    getData();
    getEpaycoKey();
  }, []);
  const initPay = async () => {
    const response = await OrdersApi.initNewOrder({
      paymentType,
      userId: paymentData.userId,
      productId: paymentData.id,
      productDocumentId: paymentData.documentId,
      productName: title,
      paymentsInstallments: paymentType === 'completo' ? 1 : 4,
      productType: 'category',
    });

    const dataToPay = {
      name: title,
      description: title,
      invoice: response.invoice,
      currency: 'cop',
      amount: `${response.price}`,
      tax_base: '0',
      tax: '0',
      country: 'co',
      lang: 'es',
      external: 'false',

      // Atributos opcionales
      extra1: new Date().toLocaleString(),
      extra2: paymentData.nameCategory || 'NA',
      extra3: 'extra3',
      confirmation: 'https://node-backend-template-dot-plataformaeducacion.ue.r.appspot.com/api/epayco',
      response: process.env.REACT_APP_RESPONSE_PAYMENTS,
      methodsDisable: [],
      p_confirm_method: 'POST',
    };
    handler.open(dataToPay);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onClosed={() => { }}
        style={{
          borderRadius: '300px',
        }}
      >
        <img alt={'dsds'} src={teeneager} />
        <DialogTitle
          className={'shop-title'}
        >
          {title}
        </DialogTitle>
        <DialogContent>Ruta de aprendizaje</DialogContent>
        <div style={{
          paddingTop: 0,
          paddingBottom: 0,
          fontSize: '1.4rem',
          color: '#555',
          textAlign: 'center',
          textDecoration: 'line-through',
        }}
        >{toCurrency(paymentData.prices?.priceNoDiscount || 0)} COP
        </div>
        <div style={{
          paddingTop: 0,
          paddingBottom: 0,
          fontSize: '1.6rem',
          color: '#252',
          textAlign: 'center',
        }}
        >{toCurrency(priceToPay)} COP
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          marginTop: '10px',
        }}
        >
          <select
            className={'select-price'}
            name={'select'}
            onChange={(e) => {
              const { prices } = paymentData;
              if (e.target.value === 'completo') setPriceToPay(prices['0']);
              if (e.target.value === '2cuotas') setPriceToPay(prices['1']);
              if (e.target.value === '4cuotas') setPriceToPay(prices['1']);
              setpaymentType(e.target.value);
            }}
            disabled={paymentInProgress.progressPayment}
          >
            <option
              style={{ textAlign: 'center' }}
              value={'completo'}
              selected
            >{paymentInProgress.progressPayment ? `Cuota número ${paymentInProgress.nextInstallment}` : 'Pago completo'}
            </option>
            <option style={{ textAlign: 'center' }} value={'4cuotas'}>4 Cuotas</option>
          </select>
        </div>
        <DialogContent>
          {description}
        </DialogContent>
        <DialogActions>
          {/* <DialogButton
            style={{
              backgroundColor: '#E79200', color: '#FFF', borderRadius: '20px', padding: '5px 25px',
            }}
            action={'show_more'}
          >Ver más
          </DialogButton> */}
          <button
            type={'button'}
            style={{
              backgroundColor: '#2D2D2D', borderRadius: '20px', width: '35px', height: '35px', border: 'none', margin: '4px',
            }}
            onClick={initPay}
          >
            <img alt={'course'} src={cart} />
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProductShop;
