import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './Slider.css';

const Slider = (props) => {
  const {
    firstText, secondText, mainSlide, children, buttonText, bottom,
  } = props;
  const href = process.env.REACT_APP_EMPLEOS_LOGIN;
  const bottomText = bottom ? 'main-label main-label-bottom' : 'main-label';
  return (
    <div className={'carousel'}>
      <Carousel showArrows dynamicHeight={false} showThumbs={false}>
        <div>
          <img className={'mainSlide'} alt={'BX Logo'} src={mainSlide} />
          <div className={bottomText}>
            <div className={'__main-label'}>
              <p>{firstText} <span>{secondText}</span></p>
            </div>
          </div>
          <div className={'callAction_m'}>
            <div className={'__callAction_m'}>
              <a className={'callAction'} href={href} target={'_blank'} rel={'noreferrer'}>
                <div>
                  {buttonText}
                </div>
              </a>
            </div>
          </div>
          {children}
        </div>
      </Carousel>
    </div>
  );
};

export default Slider;
