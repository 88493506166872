/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import IconStepper from '../../res/images/iconStepper.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  activeStepIcon: {
    color: theme.palette.secondary.main,
  },
}));

const CustomStepper = ({ activeStep, steps }) => {
  const classes = useStyles();

  return (
    <Stepper activeStep={activeStep}>
      {steps.map((label, index) => {
        const stepProps = {};
        if (index === activeStep) {
          // Reemplazar StepLabel con Avatar solo para el paso actual
          return (
            <Step key={label} {...stepProps}>
              <Avatar
                src={IconStepper} // Establecer la ruta de la imagen "image1"
                alt={'icono del stepper'} // Establecer el texto alternativo de la imagen
                className={classes.activeStepIcon} // Agregar la clase
                // de estilo del icono del paso actual
              />
            </Step>
          );
        }

        // Renderizar StepLabel para los demás pasos
        return (
          <Step key={label} {...stepProps}>
            <StepLabel
              StepIconProps={{
                className:
                  index < activeStep
                    ? classes.stepIcon // Aplicar clase de estilo para los pasos no completados
                    : index === activeStep
                      ? classes.activeStepIcon // Aplicar clase de estilo para el paso actual
                      : classes.completedStepIcon, // Aplicar clase de estilo
                // para los pasos completados
              }}
            />
          </Step>
        );
      })}
    </Stepper>
  );
};

export default CustomStepper;
