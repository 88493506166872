import React, { useEffect, useState } from 'react';
import './Shop.css';
import { useLocation } from 'react-router-dom';
import OrdersApi from '../../api/orders';
import esfera from '../../res/images/ESFERA_1.png';
import wallet from '../../res/images/Wallet_2.png';
import gusano from '../../res/images/gusano.png';
import emoji from '../../res/images/emoji.png';

const ResponsePayments = () => {
  const [transaction, setTransaction] = useState({});
  const location = useLocation();
  const epaycoRef = new URLSearchParams(location.search).get('ref_payco');
  useEffect(() => {
    const getDataEpayco = async () => {
      const data = await OrdersApi.getTransaction(epaycoRef);
      setTransaction(data);
    };
    getDataEpayco();
  }, []);
  const imgResponse = transaction.status === 'error' ? emoji : wallet;
  return (
    <div className={'section-response'}>
      <div className={'section-response__'}>
        <div className={'card-response'}>
          <img className={'espera-payments'} alt={'esfera'} src={esfera} />
          <img className={'gusano-payments'} alt={'esfera'} src={gusano} />
          <img className={'wallet-payments'} alt={'esfera'} src={imgResponse} />
          {(transaction.x_response !== 'Aceptada') ? (
            <>
              <h3><span>Algo</span> SALIÓ MAL</h3>
              <p>LO SENTIMOS</p>
              <p>Por favor vuelve a intentarlo mas tarde</p>
              <br />
              <a className={'go-back'} href={'/home'}>Volver</a>
            </>)
            : (
              <>
                <h3><span>Pago</span> REALIZADO</h3>
                <p>EXITOSAMENTE</p>
                <p>Tu referencia de pago es: <span>{epaycoRef}</span></p>
                <p>Tu ID de orden es: <span>{transaction.x_id_invoice}</span></p>
                <p>Tu orden fue: <span>{transaction.x_response}</span></p>
                <a className={'go-back'} href={'/home'}>Continuar</a>
              </>)}
        </div>
      </div>
    </div>
  );
};

export default ResponsePayments;
